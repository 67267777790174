<template>
    <ValidationObserver tag="form" ref="observer" @submit.prevent>
        <div class="wizard-starting__page company">
            <div class="wizard-starting__logo">
                <img src="../../../assets/img/wikiworks-logo.png" alt="Wikiworks">
            </div>
            <div class="wizard-starting__row">
                <div class="wizard-starting__col wizard-starting__col_left">
                    <div class="wizard-starting__block">
                        <div>
                            <h3 class="wizard-starting__title">
                                Давайте определимся, в какой компании начинаем наводить порядок?
                            </h3>
                            <ValidationProvider rules="required|max_length:255" vid="company" v-slot="provider" slim>
                                <div
                                    class="wizard-starting__form-group"
                                    :class="{'is-invalid': provider.errors.length > 0}"
                                >
                                    <label for="company" class="wizard-starting__form-group-label">
                                        Ваша компания называется
                                    </label>
                                    <input
                                        id="company"
                                        name="company"
                                        type="text"
                                        class="wizard-starting__form-group-input"
                                        v-model="form.company"
                                        maxlength="255"
                                    >
                                    <div id="company-error"
                                         class="wizard-starting__form-group-error animated fadeInDown"
                                         v-if="provider.errors.length > 0"
                                    >
                                        {{provider.errors[0]}}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <div class="wizard-starting__btn-group">
<!--                                <button-->
<!--                                    class="wizard-starting__btn wizard-starting__btn_prev"-->
<!--                                    @click="onPrev"-->
<!--                                >-->
<!--                                    Назад-->
<!--                                </button>-->
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_next"
                                    @click="onNext"
                                >
                                    Далее
                                </button>
                            </div>
                        </div>
                        <div>
                            <progress-bar-step :step="2"></progress-bar-step>
                        </div>
                    </div>
                </div>
                <div class="wizard-starting__col wizard-starting__col_right">
                    <svg width="960" height="937" viewBox="0 0 960 937" fill="none" xmlns="http://www.w3.org/2000/svg"
                         preserveAspectRatio="xMidYMin slice">
                        <rect width="960" height="937" fill="#F2F2F2"/>
                        <rect y="551" width="960" height="386" fill="#FBC04F"/>
                        <g clip-path="url(#clip0)">
                            <path
                                d="M680.287 585.815H347.872C341.373 585.815 334.874 588.46 330.3 593.028C325.727 597.596 319.228 600.24 312.729 600.24H241.72C234.018 600.24 228 606.491 228 613.944C228 621.638 234.258 627.648 241.72 627.648H282.4C292.991 627.648 303.1 631.976 310.803 639.429C318.265 646.882 328.615 651.209 339.206 651.209H413.344C421.528 651.209 429.23 654.335 434.767 660.105C440.544 665.875 448.246 669 456.19 669H621.314C628.776 669 635.757 666.115 641.052 660.826C646.348 655.537 653.328 652.652 660.79 652.652H668.252C677.399 652.652 686.064 649.046 692.563 642.554C699.062 636.063 707.728 632.457 716.875 632.457H730.354C738.779 632.457 745.759 625.484 745.759 617.07C745.759 608.655 738.779 601.683 730.354 601.683H723.374C715.43 601.683 707.728 598.798 701.71 593.749C695.933 588.46 688.23 585.815 680.287 585.815Z"
                                fill="#EF9116"/>
                            <path
                                d="M548.862 426.897C554.157 432.186 557.046 439.399 559.934 446.371C563.545 454.785 567.155 463.441 570.525 471.855C573.414 478.827 576.302 485.8 577.987 493.012C579.431 499.263 579.913 505.755 581.838 512.005C583.523 518.256 586.893 524.267 592.429 527.633C597.966 530.999 606.15 530.518 610.001 525.229C612.649 521.863 612.89 517.535 613.13 513.208C615.297 455.266 591.467 396.604 549.584 356.694C545.973 353.088 541.881 349.722 537.067 348.279C532.253 346.837 526.235 348.279 523.587 352.607C521.421 356.454 522.384 361.502 524.069 365.589C525.754 369.677 528.161 373.764 528.642 378.091C529.364 386.025 523.828 393.478 524.791 401.172C525.513 407.182 529.846 411.991 534.419 415.597C539.474 419.684 544.77 422.569 548.862 426.897Z"
                                fill="#566666"/>
                            <path
                                d="M678.121 430.984C671.381 440.12 677.399 454.786 669.937 463.2C665.123 468.49 656.457 469.211 652.606 475.221C650.439 478.828 650.439 483.396 650.199 487.723C649.236 499.023 643.94 509.842 635.275 517.295C626.85 524.748 615.296 528.595 603.983 528.114C613.371 474.26 639.608 423.291 677.88 384.343C690.397 371.6 706.043 359.579 723.855 361.022C730.354 361.503 737.816 365.59 737.575 371.841C737.334 375.928 733.965 379.294 730.354 381.457C726.743 383.621 722.892 385.304 720.244 388.67C714.708 395.642 717.837 406.942 712.542 414.395C705.321 424.974 686.064 420.406 678.121 430.984Z"
                                fill="#6E7B7C"/>
                            <path
                                d="M524.791 430.984C531.531 440.12 525.513 454.786 532.975 463.2C537.789 468.49 546.454 469.211 550.306 475.221C552.472 478.828 552.472 483.396 552.713 487.723C553.676 499.023 558.971 509.842 567.637 517.295C576.061 524.748 587.615 528.595 598.928 528.114C589.541 474.26 563.304 423.291 525.032 384.343C512.515 371.6 496.869 359.579 479.057 361.022C472.558 361.503 465.096 365.59 465.336 371.841C465.577 375.928 468.947 379.294 472.558 381.457C476.168 383.621 480.02 385.304 482.667 388.67C488.204 395.642 485.074 406.942 490.37 414.395C497.832 424.974 517.088 420.406 524.791 430.984Z"
                                fill="#6E7B7C"/>
                            <path d="M573.654 594.469H618.185L633.109 524.748H558.971L573.654 594.469Z" fill="white"/>
                            <path d="M360.629 599.038H464.374L417.436 579.805H407.567L360.629 599.038Z" fill="#493A60"/>
                            <path d="M417.436 509.602H407.567V524.748H417.436V509.602Z" fill="#332A44"/>
                            <path d="M417.436 524.748H407.567V579.804H417.436V524.748Z" fill="#493A60"/>
                            <path
                                d="M319.709 374.005L329.337 366.792L423.454 372.322H422.491C425.62 372.562 428.508 374.726 429.471 377.611L471.113 496.859L366.165 509.602L364.24 507.197C358.944 500.466 354.852 492.772 351.964 484.598L316.821 383.141C315.617 379.775 317.061 376.168 319.709 374.005Z"
                                fill="#471E3B"/>
                            <path d="M366.199 509.488H480.775V489.774H366.199V509.488Z" fill="#471E3B"/>
                            <path
                                d="M366.165 489.887H480.742L439.34 370.879C438.136 367.754 435.248 365.59 431.637 365.59H334.151C328.615 365.59 324.764 371.12 326.449 376.168L366.165 489.887Z"
                                fill="#865978"/>
                            <path
                                d="M491.092 492.051C486.519 489.406 480.02 489.166 476.89 484.598C473.28 479.549 475.446 471.375 467.262 469.932C465.096 469.451 462.689 469.692 460.763 468.73C458.597 467.768 457.393 465.605 455.468 464.162C450.653 460.315 443.673 462.239 438.377 463.681C432.841 465.364 427.786 468.49 422.972 471.856V455.267H465.577L520.699 476.664C532.012 480.992 538.27 493.253 535.141 505.034L511.552 594.47H483.389L500.239 505.274L497.832 504.793C497.832 504.553 497.832 504.072 497.832 503.831C497.832 499.023 495.184 494.455 491.092 492.051Z"
                                fill="#675186"/>
                            <path
                                d="M422.972 471.855C427.786 468.49 432.841 465.364 438.377 463.681C443.673 462.239 450.653 460.556 455.468 464.162C457.393 465.604 458.597 467.528 460.763 468.73C462.689 469.692 465.096 469.692 467.262 469.932C475.205 471.375 473.039 479.549 476.89 484.598C480.02 488.925 486.278 489.166 491.092 492.051C495.184 494.455 498.073 499.023 498.313 503.831C498.313 504.072 498.313 504.553 498.313 504.793L422.972 489.887V471.855Z"
                                fill="#473B59"/>
                            <path
                                d="M415.029 496.859C422.731 494.455 430.193 490.849 436.452 486.281V497.34H419.843C418.399 497.34 416.714 497.34 415.029 496.859Z"
                                fill="#473B59"/>
                            <path
                                d="M415.029 496.859C401.549 494.455 391.44 481.953 392.643 467.768L393.606 455.026H436.452L463.411 471.134C471.113 475.702 475.205 484.598 473.761 493.493L457.393 594.229H429.953L436.452 497.34V486.281C430.193 491.089 422.972 494.695 415.029 496.859Z"
                                fill="#675186"/>
                            <path
                                d="M487.481 594.47H506.979C506.979 600.24 510.108 605.529 514.922 608.414L527.679 615.867C529.605 617.069 531.049 618.752 532.012 620.676H481.704L487.481 594.47Z"
                                fill="#471E3B"/>
                            <path
                                d="M532.012 620.675C532.734 622.118 532.975 623.801 532.975 625.243H480.742L481.705 620.675H532.012Z"
                                fill="white"/>
                            <path
                                d="M434.045 594.47H453.542C453.542 600.24 456.671 605.529 461.485 608.414L474.243 615.867C476.168 617.069 477.612 618.752 478.575 620.676H428.268L434.045 594.47Z"
                                fill="#471E3B"/>
                            <path
                                d="M478.334 620.675C479.057 622.118 479.297 623.801 479.297 625.243H427.064L428.027 620.675H478.334Z"
                                fill="white"/>
                            <path
                                d="M455.468 356.454L479.538 371.6L468.947 394.68C466.781 399.489 465.577 404.778 465.577 410.067V455.266H393.847V414.395C393.847 400.931 397.217 387.468 403.475 375.447L410.696 361.983L433.563 355.251L455.468 356.454Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M445.839 365.589L452.579 369.677L449.209 377.851H443.192L438.859 370.879L445.839 365.589Z"
                                fill="#F7507B"/>
                            <path
                                d="M449.209 377.851L457.634 446.371L445.839 453.824L433.563 445.409L443.192 377.851H449.209Z"
                                fill="#F7507B"/>
                            <path
                                d="M436.211 349.001L439.1 342.99L457.634 351.165L449.691 361.022C447.043 360.3 444.636 359.339 442.47 357.656C439.581 355.252 437.415 352.367 436.211 349.001Z"
                                fill="#F7507B"/>
                            <path
                                d="M433.563 355.011L436.452 349.001C437.655 352.367 439.822 355.252 442.47 357.416C444.636 359.099 447.043 360.301 449.691 360.782L445.839 365.59L433.563 355.011Z"
                                fill="#FF9493"/>
                            <path d="M445.84 365.59L454.986 354.29L457.634 372.802L445.84 365.59Z" fill="#7984C9"/>
                            <path d="M433.563 355.011L445.839 365.59L435.008 374.004L433.563 355.011Z" fill="#7984C9"/>
                            <path
                                d="M476.168 335.777C483.148 330.248 487.722 321.593 488.203 312.697C488.684 303.802 485.074 294.666 478.575 288.415C472.076 282.164 462.688 279.038 453.782 280C448.486 280.481 443.432 282.404 439.58 286.01C435.729 289.376 433.081 294.425 432.84 299.714C429.711 299.474 427.064 302.119 425.619 304.763C424.175 307.408 423.934 310.533 423.934 313.659C423.934 320.15 425.138 326.882 429.23 331.931C434.285 338.422 442.95 341.307 451.134 341.307C459.077 341.307 469.669 340.826 476.168 335.777Z"
                                fill="#471E3B"/>
                            <path
                                d="M475.206 343.471C478.816 343.952 482.427 342.99 485.315 340.826C486.519 339.864 487.723 338.662 488.204 337.22C488.685 335.777 488.685 334.094 487.963 332.892C486.76 330.969 484.353 330.488 482.186 330.729C480.02 331.209 478.094 332.411 476.409 333.614C475.687 336.499 475.687 340.826 475.206 343.471Z"
                                fill="#F7507B"/>
                            <path
                                d="M481.464 314.38C481.223 321.593 481.223 329.046 479.298 336.259C477.372 343.231 473.28 349.963 466.781 353.809C461.004 357.175 453.783 357.656 447.765 355.011C441.748 352.367 436.933 347.077 435.249 340.586C432.841 341.548 429.231 340.827 427.064 339.384C424.898 337.701 423.454 335.056 423.695 332.171C423.935 330.248 424.657 328.565 426.102 327.363C427.546 326.161 429.472 325.68 431.397 326.161C433.323 326.642 434.526 328.325 435.249 330.008C435.971 331.691 435.971 333.614 435.971 335.537C437.415 335.778 438.859 333.854 439.1 332.412C439.341 330.969 439.1 329.527 438.618 328.084C438.378 326.642 438.137 324.959 438.859 323.757C439.581 322.314 441.266 321.352 442.47 320.391C445.358 317.987 446.802 313.659 445.599 310.053C456.671 313.659 469.67 315.582 481.464 314.38Z"
                                fill="#FF9493"/>
                            <path
                                d="M473.039 385.064L479.297 371.6C485.074 375.928 489.407 381.698 491.573 388.67L503.609 426.175H534.66L529.124 436.994H495.906C489.648 436.994 484.112 433.148 481.945 427.378L475.446 411.029C477.131 402.374 476.168 393.238 473.039 385.064Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M473.039 385.064C476.168 393.238 476.89 402.134 475.687 410.789L468.947 393.96L473.039 385.064Z"
                                fill="#7984C9"/>
                            <path
                                d="M546.454 425.935C547.417 424.973 548.861 424.493 550.306 424.012C551.75 423.531 552.953 423.05 554.157 422.088C555.12 421.127 555.842 419.684 555.361 418.242C554.879 417.04 553.916 416.559 552.713 416.318C551.509 416.078 550.306 416.318 549.343 416.559C543.084 417.761 537.067 420.646 532.012 424.733C531.049 425.454 530.086 426.416 529.123 427.137C529.123 425.454 527.679 424.012 526.235 423.531C524.55 423.05 522.865 423.771 521.902 425.214C521.421 429.061 524.55 434.35 527.679 436.994H554.879C555.12 434.831 554.638 431.946 553.435 430.022C551.75 427.858 548.621 426.416 546.454 425.935Z"
                                fill="#FF9493"/>
                            <path
                                d="M535.382 428.58L537.067 427.859C541.881 425.455 547.658 425.455 552.472 427.618L557.045 429.782C558.249 430.263 558.971 431.465 558.971 432.667H532.012C532.493 430.984 533.697 429.542 535.382 428.58Z"
                                fill="#675186"/>
                            <path
                                d="M558.971 432.667C558.971 433.148 558.73 433.87 558.49 434.35L556.805 436.995H531.771C531.29 435.553 531.29 434.11 531.771 432.908H558.971V432.667Z"
                                fill="#473B59"/>
                            <path
                                d="M363.518 419.203L389.514 377.61C394.328 369.917 401.79 364.147 410.696 361.743L412.14 372.802C413.344 381.698 410.696 390.834 404.679 397.565C393.606 401.893 383.496 409.586 376.516 418.963L384.941 420.165L417.195 425.935V436.754H373.146C364.24 436.754 358.704 426.897 363.518 419.203Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M404.438 397.565L384.7 420.164L376.275 418.962C383.496 409.586 393.365 401.893 404.438 397.565Z"
                                fill="#7984C9"/>
                            <path
                                d="M415.51 421.847C417.917 420.165 419.843 420.165 419.843 420.165H430.434C435.489 420.165 440.303 423.05 442.951 427.377C443.914 429.06 442.71 430.983 440.784 430.983H432.36C430.434 430.983 428.99 432.186 428.268 433.869C427.546 435.551 426.101 436.754 424.176 436.754H412.622C411.178 436.754 410.215 435.792 409.974 434.349C409.252 429.541 411.418 424.733 415.51 421.847Z"
                                fill="#FF9493"/>
                            <path d="M678.843 489.887H667.77V631.495H678.843V489.887Z" fill="#D09DA0"/>
                            <path d="M678.843 453.824H667.77V489.647H678.843V453.824Z" fill="#B27D82"/>
                            <path d="M667.77 489.887H656.698V631.495H667.77V489.887Z" fill="#B27D82"/>
                            <path d="M667.77 453.824H656.698V489.647H667.77V453.824Z" fill="#8E6168"/>
                            <path d="M396.735 453.824H385.662V489.647H396.735V453.824Z" fill="#B27D82"/>
                            <path d="M396.735 489.887H385.662V631.495H396.735V489.887Z" fill="#D09DA0"/>
                            <path d="M385.662 453.824H374.59V489.647H385.662V453.824Z" fill="#8E6168"/>
                            <path d="M385.662 489.887H374.59V631.495H385.662V489.887Z" fill="#B27D82"/>
                            <path d="M592.189 453.824H581.116V489.647H592.189V453.824Z" fill="#B27D82"/>
                            <path d="M592.189 489.887H581.116V614.665H592.189V489.887Z" fill="#D09DA0"/>
                            <path d="M581.116 453.824H570.043V489.647H581.116V453.824Z" fill="#8E6168"/>
                            <path d="M581.116 489.887H570.043V614.665H581.116V489.887Z" fill="#B27D82"/>
                            <path d="M310.081 453.824H299.008V489.647H310.081V453.824Z" fill="#B27D82"/>
                            <path d="M310.081 489.887H299.008V614.665H310.081V489.887Z" fill="#D09DA0"/>
                            <path d="M299.008 489.887H287.936V614.665H299.008V489.887Z" fill="#B27D82"/>
                            <path d="M299.008 453.824H287.936V489.647H299.008V453.824Z" fill="#8E6168"/>
                            <path d="M360.388 436.754H273.734V453.824H360.388V436.754Z" fill="#B27D82"/>
                            <path d="M692.804 436.754H360.629V453.824H692.804V436.754Z" fill="#D09DA0"/>
                            <path d="M385.896 471.907L668.004 471.907V453.875L385.896 453.875V471.907Z" fill="#B27D82"/>
                            <path d="M299.161 471.907H385.815V453.875H299.161V471.907Z" fill="#8E6168"/>
                            <path
                                d="M551.269 407.423H649.477C653.569 407.423 656.698 404.057 656.698 400.21V323.276C656.698 319.188 653.328 316.063 649.477 316.063H551.269C547.177 316.063 544.048 319.429 544.048 323.276V400.21C544.048 404.297 547.177 407.423 551.269 407.423Z"
                                fill="#7984C9"/>
                            <path
                                d="M551.75 323.516C551.75 319.429 555.12 316.063 559.212 316.063H656.939C661.031 316.063 664.401 319.429 664.401 323.516V399.97C664.401 404.057 661.031 407.423 656.939 407.423H651.884L551.75 329.527V323.516Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M651.884 407.423H559.212C555.12 407.423 551.75 404.057 551.75 399.97V329.527L651.884 407.423Z"
                                fill="#95A8F7"/>
                            <path
                                d="M605.428 433.869L610.001 430.984V421.848L599.41 368.715H617.704H620.592L632.628 424.252C634.072 430.743 629.017 436.754 622.518 436.754H620.592H605.428H597.244V433.869H605.428Z"
                                fill="white"/>
                            <path
                                d="M600.854 433.869C605.428 433.869 609.038 429.541 608.076 424.973L599.17 382.419V368.715L611.205 424.252C612.649 430.743 607.594 436.754 601.095 436.754H575.58V433.869H600.854Z"
                                fill="#A9BEFF"/>
                            <path d="M524.791 431.224H447.043V436.754H524.791V431.224Z" fill="#675186"/>
                            <path d="M447.043 431.224H416.955V436.754H447.043V431.224Z" fill="#473B59"/>
                        </g>
                        <g clip-path="url(#clip1)">
                            <path d="M867.698 329.58H768.151V340.696H867.698V329.58Z" fill="#E8EAF0"/>
                            <path
                                d="M817.925 340.847C801.937 340.847 785.949 340.847 769.961 340.847C769.207 340.847 768.151 341.304 768 340.238C767.849 339.02 769.056 339.02 769.961 338.868C771.469 338.716 773.128 338.411 774.787 338.411C805.104 338.411 835.572 338.411 865.888 338.411C866.944 338.411 868.151 338.259 868 339.781C868 340.391 867.698 340.695 866.944 340.695C866.492 340.695 866.039 340.695 865.587 340.695C849.75 340.847 833.912 340.847 817.925 340.847Z"
                                fill="#DBDDE4"/>
                            <path d="M861.665 244H834.214V329.579H861.665V244Z" fill="#E8EAF0"/>
                            <path
                                d="M855.632 269.887C855.632 274.76 855.632 279.785 855.632 284.658C855.632 285.724 855.481 286.028 854.274 286.028C850.051 286.028 845.979 286.028 841.756 286.028C840.398 286.028 840.247 285.267 840.247 284.201C840.247 277.196 840.247 270.344 840.247 263.339C840.247 260.598 840.247 257.705 840.247 254.964C840.247 253.746 840.7 253.593 841.756 253.593C845.979 253.593 850.202 253.593 854.425 253.593C855.481 253.593 855.632 253.898 855.632 254.964C855.481 259.989 855.632 264.862 855.632 269.887Z"
                                fill="#FDFDFD"/>
                            <path
                                d="M852.615 314.047C852.615 316.788 850.655 318.768 847.94 318.92C845.225 318.92 843.113 316.94 843.113 314.199C843.113 311.306 845.074 309.327 847.94 309.327C850.655 309.174 852.615 311.154 852.615 314.047Z"
                                fill="#DBDDE4"/>
                            <path
                                d="M848.09 317.549C845.828 317.549 844.32 316.027 844.32 313.895C844.32 311.915 845.979 310.24 847.939 310.24C849.9 310.24 851.409 311.763 851.409 313.895C851.409 316.027 849.9 317.549 848.09 317.549Z"
                                fill="#FFE592"/>
                            <path d="M802.238 244H774.787V329.579H802.238V244Z" fill="#E8EAF0"/>
                            <path
                                d="M788.513 310.088C791.228 310.088 793.189 312.22 793.189 314.961C793.189 317.702 791.077 319.681 788.362 319.681C785.647 319.681 783.535 317.549 783.686 314.808C783.686 312.067 785.647 310.088 788.513 310.088Z"
                                fill="#DBDDE4"/>
                            <path
                                d="M780.821 254.811C785.647 254.811 790.323 254.811 795.149 254.659C796.356 254.659 796.205 255.573 796.205 256.182C796.205 264.253 796.205 272.171 796.205 280.242C796.205 282.069 796.205 283.896 796.205 285.876C796.205 286.485 796.054 287.094 795.3 287.094C790.474 287.094 785.798 287.094 780.971 286.942C780.821 276.13 780.821 265.471 780.821 254.811Z"
                                fill="#FDFDFD"/>
                            <path
                                d="M791.982 314.961C791.982 316.94 790.323 318.615 788.362 318.463C786.401 318.463 784.893 316.788 784.893 314.808C784.893 312.829 786.552 311.154 788.513 311.154C790.624 311.458 792.133 312.981 791.982 314.961Z"
                                fill="#FFE592"/>
                            <path d="M831.952 244H804.501V329.579H831.952V244Z" fill="#DBDDE4"/>
                            <path
                                d="M825.768 270.648C825.768 275.521 825.768 280.394 825.768 285.267C825.768 286.637 825.466 287.094 823.958 287.094C819.885 286.942 815.964 287.094 811.891 287.094C810.836 287.094 810.383 286.789 810.383 285.724C810.383 275.826 810.383 265.775 810.383 255.877C810.534 254.964 810.836 254.659 811.741 254.659C815.964 254.659 820.338 254.659 824.561 254.659C825.617 254.659 825.768 254.964 825.768 255.877C825.768 260.903 825.768 265.775 825.768 270.648Z"
                                fill="#FDFDFD"/>
                            <path
                                d="M818.226 310.088C820.941 310.088 822.902 312.067 822.902 314.808C822.902 317.702 820.941 319.834 818.226 319.834C815.511 319.834 813.249 317.854 813.249 315.113C813.4 312.067 815.21 310.088 818.226 310.088Z"
                                fill="#E8EAF0"/>
                            <path
                                d="M818.226 318.615C816.265 318.615 814.606 317.093 814.606 314.961C814.606 312.981 816.114 311.306 818.226 311.306C820.187 311.306 821.846 312.829 821.846 314.809C821.846 316.788 820.187 318.463 818.226 318.615Z"
                                fill="#FFE592"/>
                        </g>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M639.5 154C645.299 154 650 149.299 650 143.5C650 137.701 645.299 133 639.5 133C633.701 133 629 137.701 629 143.5C629 149.299 633.701 154 639.5 154ZM639.5 162C649.717 162 658 153.717 658 143.5C658 133.283 649.717 125 639.5 125C629.283 125 621 133.283 621 143.5C621 153.717 629.283 162 639.5 162Z"
                              fill="#FBC04F"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M344.821 130.164C346.387 131.722 346.394 134.255 344.836 135.821L311.707 169.121C307.016 173.836 299.384 173.836 294.693 169.121L267.236 141.522C265.672 139.95 263.128 139.95 261.564 141.522L234.107 169.121C229.416 173.836 221.784 173.836 217.093 169.121L189.636 141.522C188.072 139.95 185.528 139.95 183.964 141.522L150.836 174.821C149.278 176.387 146.745 176.394 145.179 174.836C143.613 173.278 143.606 170.745 145.164 169.179L178.293 135.879C182.984 131.164 190.616 131.164 195.307 135.879L222.764 163.478C224.328 165.05 226.872 165.05 228.436 163.478L255.893 135.879C260.584 131.164 268.216 131.164 272.907 135.879L300.364 163.478C301.928 165.05 304.472 165.05 306.036 163.478L339.164 130.179C340.722 128.613 343.255 128.606 344.821 130.164Z"
                              fill="#E0E3F0"/>
                        <rect x="325" y="277.105" width="9" height="37" rx="4.5"
                              transform="rotate(-56.7022 325 277.105)"
                              fill="#FBC04F"/>
                        <rect x="382.433" y="223.079" width="9" height="37" rx="4.5"
                              transform="rotate(-20.0028 382.433 223.079)" fill="#FBC04F"/>
                        <defs>
                            <clipPath id="clip0">
                                <rect x="228" y="280" width="518" height="389" fill="white"/>
                            </clipPath>
                            <clipPath id="clip1">
                                <rect x="768" y="244" width="100" height="97" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
        <Keypress key-event="keyup" :key-code="13" @success="onNext"/>
    </ValidationObserver>
</template>

<script>
    import ProgressBarStep from "./ProgressBarStep";

    export default {
        name: "CompanyStep",
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        components: {
            ProgressBarStep,
            Keypress: () => import('vue-keypress')
        },
        methods: {
            onPrev() {
                this.$emit('prev');
            },
            async onNext() {
                let isValid = await this.$refs.observer.validate();
                if (isValid) {
                    this.$emit('next');
                }
            }
        },
        async mounted() {
            if (this.$metrika) await this.$metrika.reachGoal('company-step');
        }
    }
</script>

<style lang="scss" scoped>
</style>
