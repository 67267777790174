<template>
    <ValidationObserver tag="form" ref="observer" @submit.prevent>
        <div class="wizard-starting__page testing">
            <div class="wizard-starting__logo">
                <img src="../../../assets/img/wikiworks-logo.png" alt="Wikiworks">
            </div>
            <div class="wizard-starting__row">
                <div class="wizard-starting__col wizard-starting__col_left">
                    <div class="wizard-starting__block">
                        <div>
                            <h3 class="wizard-starting__title wizard-starting__title_has-subtitle">
                                Давайте убедимся, что ваши сотрудники понимают, что вы от них хотите?
                            </h3>
                            <p class="wizard-starting__subtitle">
                                Добавьте вопросы для проверки знаний только что созданного регламента.
                            </p>
                            <div
                                class="testing__item"
                                v-for="(question, indexQuestion) in form.data"
                                :key="question.uuid"
                            >
                                <h4 class="testing__title">
                                    <span>{{ indexQuestion + 1 }}. Вопрос</span>
                                    <button
                                        class="testing__remove-question"
                                        v-if="indexQuestion > 0"
                                        @click="removeQuestion(indexQuestion)"
                                    >&times;
                                    </button>
                                </h4>
                                <ValidationProvider
                                    rules="required"
                                    v-slot="provider"
                                    slim
                                >
                                    <div class="wizard-starting__form-group"
                                         :class="{'is-invalid': provider.errors.length > 0}"
                                    >
                                        <label :for="'id_type_answer_' + question.uuid"
                                               class="wizard-starting__form-group-label">
                                            Тип ответа
                                        </label>
                                        <v-select2
                                            ref="selectTypeAnswer"
                                            class="wizard-starting__form-group-select"
                                            :id="'id_type_answer' + question.uuid"
                                            v-model="question.type_answer"
                                            :reduce="label => label.value"
                                            label="label"
                                            :clearable="false"
                                            :searchable="false"
                                            :options="listTypeAnswer"
                                            @input="changeTypeAnswer(question)"
                                        >
                                            <span slot="no-options">Ничего не найдено</span>
                                        </v-select2>
                                        <div :id="'id_type_answer_' + question.uuid + 'error'"
                                             class="wizard-starting__form-group-error animated fadeInDown"
                                             v-if="provider.errors.length > 0"
                                        >
                                            {{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider
                                    rules="required|max_length:255"
                                    v-slot="provider"
                                    slim
                                >
                                    <div
                                        class="wizard-starting__form-group"
                                        :class="{'is-invalid': provider.errors.length > 0}"
                                    >
                                        <label :for="'id_text_' + question.uuid"
                                               class="wizard-starting__form-group-label">
                                            Формулировка вопроса
                                        </label>
                                        <textarea-autosize
                                            :id="'id_text_' + question.uuid"
                                            class="wizard-starting__form-group-area"
                                            :min-height="110"
                                            :max-height="350"
                                            v-model="question.text"
                                        />
                                        <div :id="'id_text_' + question.uuid + '-error'"
                                             class="wizard-starting__form-group-error animated fadeInDown"
                                             v-if="provider.errors.length > 0"
                                        >
                                            {{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <div class="wizard-starting__form-group">
                                    <label class="wizard-starting__form-group-label">Варианты ответа</label>
                                    <div class="testing__answer">
                                        <ValidationProvider
                                            v-slot="provider"
                                            tag="div"
                                            class="testing__answer-item"
                                            v-for="(answer, indexAnswer) in question.listAnswer"
                                            :vid="`${answer.uuid}_answer`"
                                            :key="answer.uuid"
                                        >
                                            <div :class="{'is-invalid': provider.errors.length > 0}">
                                                <input type="text"
                                                       class="wizard-starting__form-group-input testing__answer-input"
                                                       v-model="answer.text"
                                                >
                                                <div class="testing__answer-option-group">
                                                    <label class="testing__answer-option">
                                                        <input
                                                            type="checkbox"
                                                            @change.prevent="selectAnswer(question, answer)"
                                                            class="hidden"
                                                            :name="'id_answer_set-' + answer.uuid + '-correct'"
                                                        >
                                                        <span
                                                            :class="{'testing__answer-option_selected': answer.checkbox}">
                                                            <svg width="27" height="18" viewBox="0 0 27 18" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M25.5 1L10 16.5L1 7.5" stroke="#E0E3F0"
                                                                      stroke-width="2"></path>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                    <button
                                                        type="button"
                                                        class="testing__answer-option"
                                                        @click="removeAnswer(indexQuestion, indexAnswer)"
                                                        v-if="indexAnswer > 1"
                                                    >
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 1L1.37838 14.6216M14.6216 15L1 1.37838"
                                                                  stroke="#E0E3F0" stroke-width="2"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div
                                                    class="wizard-starting__form-group-error animated fadeInDown"
                                                    v-if="provider.errors.length > 0"
                                                >
                                                    {{provider.errors[0]}}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <button
                                            type="button" class="testing__answer-add"
                                            @click="addAnswer(indexQuestion)"
                                        >
                                            + Добавить ответ
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                class="testing__add-question"
                                @click="addQuestion"
                            >
                                <span>Добавить вопрос</span>
                            </button>

                            <div class="wizard-starting__btn-group">
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_prev"
                                    @mouseup="onPrev"
                                >
                                    Назад
                                </button>
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_next"
                                    @click="onNext"
                                >
                                    Далее
                                </button>
                            </div>
                            <button class="v-btn v-btn--skip" @click="onSkip">
                                Пропустить
                                <span class="v-btn-icon">
                                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="round"/>
                                        <path d="M4 4L1 7" stroke="black" stroke-linecap="round"/>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div>
                            <progress-bar-steps :step="5"></progress-bar-steps>
                        </div>
                    </div>
                </div>
                <div class="wizard-starting__col wizard-starting__col_right">
                    <svg width="960" height="937" viewBox="0 0 960 937" fill="none" preserveAspectRatio="xMidYMid slice"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="960" height="937" fill="#F2F2F2"/>
                        <rect y="540" width="960" height="397" fill="#FBC04F"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M519 91V141H569L519 91ZM527 110.314V133H549.686L527 110.314Z" fill="#E0E3F0"/>
                        <path
                            d="M286.903 235.002C286.903 235.002 243.442 232.859 266.473 365.398C266.473 365.398 282.006 410.394 262.418 439.358H550.728V235.002H286.903Z"
                            fill="#AFCDFB"/>
                        <path d="M606.202 313.439H305.19V706.043H606.202V313.439Z" fill="#9ABCEF"/>
                        <path d="M610.983 313.439H315.021V706.043H610.983V313.439Z" fill="white"/>
                        <path
                            d="M610.946 313.439H314.984C316.629 291.056 314.143 274.794 310.202 263.124C302.627 240.665 289.543 235.002 289.543 235.002H568.633C592.467 235.002 610.908 249.35 615.958 268.405C616.149 269.131 616.302 269.82 616.455 270.547C621.275 292.356 611.175 313.018 610.946 313.439Z"
                            fill="white"/>
                        <path
                            d="M610.945 313.439H314.983C316.628 291.056 314.142 274.795 310.201 263.125C340.463 268.673 415.333 280.458 495.521 278.468C547.399 277.167 588.373 273.724 616.454 270.548C621.274 292.357 611.174 313.018 610.945 313.439Z"
                            fill="#D8E8FD"/>
                        <path
                            d="M532.785 403.966H375.852C374.245 403.966 372.906 402.665 372.906 401.02V399.528C372.906 397.921 374.207 396.582 375.852 396.582H532.785C534.392 396.582 535.731 397.882 535.731 399.528V401.02C535.693 402.627 534.392 403.966 532.785 403.966Z"
                            fill="#BBD5FC"/>
                        <path
                            d="M472.032 416.363H375.202C373.939 416.363 372.906 415.33 372.906 414.067V411.312C372.906 410.05 373.939 409.017 375.202 409.017H472.032C473.294 409.017 474.327 410.05 474.327 411.312V414.067C474.366 415.292 473.294 416.363 472.032 416.363Z"
                            fill="#BBD5FC"/>
                        <path
                            d="M357.794 415.176V400.14H355.805C354.619 400.14 354.006 399.642 354.006 398.647C354.006 397.92 354.466 397.423 355.422 397.117C355.919 396.964 356.685 396.849 357.717 396.772C358.789 396.696 359.63 396.658 360.242 396.658C360.855 396.658 361.237 396.773 361.428 397.04C361.581 397.308 361.658 397.92 361.658 398.953V415.215H363.686C365.025 415.215 365.675 415.789 365.675 416.936C365.675 418.123 365.025 418.735 363.686 418.735H355.46C354.886 418.735 354.389 418.582 354.006 418.276C353.624 417.97 353.433 417.51 353.433 416.975C353.433 415.827 354.121 415.215 355.46 415.215H357.794V415.176Z"
                            fill="#BBD5FC"/>
                        <path
                            d="M532.785 481.485H375.852C374.245 481.485 372.906 480.184 372.906 478.538V477.046C372.906 475.439 374.207 474.1 375.852 474.1H532.785C534.392 474.1 535.731 475.401 535.731 477.046V478.538C535.693 480.184 534.392 481.485 532.785 481.485Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M472.032 493.881H375.202C373.939 493.881 372.906 492.848 372.906 491.586V488.831C372.906 487.568 373.939 486.535 375.202 486.535H472.032C473.294 486.535 474.327 487.568 474.327 488.831V491.586C474.366 492.848 473.294 493.881 472.032 493.881Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M366.02 492.389C366.02 493.384 365.331 493.881 363.916 493.881H355.193C353.93 493.881 353.318 493.384 353.318 492.351C353.318 492.045 353.471 491.662 353.816 491.241C354.313 490.667 355.04 489.826 355.996 488.678C357.45 486.841 358.674 485.273 359.631 483.933C359.975 483.474 360.434 482.824 361.085 481.905C361.582 480.987 361.85 480.222 361.85 479.61C361.85 478.921 361.62 478.347 361.2 477.888C360.74 477.429 360.205 477.199 359.516 477.199C358.828 477.199 358.254 477.429 357.756 477.85C357.412 478.118 357.144 478.653 356.953 479.457C356.723 480.452 356.532 481.064 356.456 481.217C356.188 481.791 355.767 482.059 355.155 482.059C354.619 482.059 354.198 481.867 353.892 481.485C353.624 481.14 353.471 480.719 353.471 480.145C353.471 479.533 353.586 478.883 353.777 478.271C354.198 476.893 354.963 475.86 356.035 475.133C357.068 474.444 358.33 474.1 359.784 474.1C361.391 474.1 362.73 474.597 363.839 475.554C364.949 476.587 365.523 477.888 365.523 479.495C365.523 480.681 365.14 481.905 364.337 483.168C363.878 483.857 362.998 485.081 361.582 486.841C360.855 487.76 359.746 489.06 358.215 490.782H363.954C365.331 490.82 366.02 491.356 366.02 492.389Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M532.784 546.185H375.851C374.244 546.185 372.905 544.884 372.905 543.239V541.746C372.905 540.139 374.206 538.8 375.851 538.8H532.784C534.391 538.8 535.73 540.101 535.73 541.746V543.239C535.692 544.846 534.391 546.185 532.784 546.185Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M472.031 558.582H375.201C373.938 558.582 372.905 557.549 372.905 556.286V553.531C372.905 552.268 373.938 551.235 375.201 551.235H472.031C473.294 551.235 474.326 552.268 474.326 553.531V556.286C474.365 557.51 473.294 558.582 472.031 558.582Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M366.019 552.384C366.019 554.259 365.369 555.789 364.03 556.937C362.767 558.008 361.199 558.582 359.286 558.582C357.794 558.582 356.531 558.238 355.498 557.511C353.815 556.401 353.012 555.024 353.012 553.455C353.012 552.996 353.165 552.613 353.433 552.269C353.739 551.925 354.083 551.772 354.542 551.772C355.001 551.772 355.46 552.078 355.881 552.728C356.57 553.761 356.952 554.373 357.067 554.45C357.679 555.1 358.444 555.406 359.362 555.406C360.625 555.406 361.543 554.947 362.079 553.991C362.347 553.532 362.461 552.919 362.461 552.192C362.461 550.432 361.428 549.438 359.324 549.246C358.138 549.131 357.564 548.634 357.564 547.754C357.564 547.104 357.909 546.644 358.597 546.377C359.86 545.917 360.51 545.65 360.548 545.65C361.237 545.191 361.581 544.425 361.581 543.277C361.581 542.589 361.39 542.091 360.969 541.67C360.587 541.288 360.051 541.096 359.362 541.096C358.521 541.096 357.87 541.288 357.411 541.709C357.029 542.053 356.684 542.627 356.417 543.469C356.187 544.119 355.766 544.425 355.154 544.425C354.733 544.425 354.351 544.234 354.083 543.851C353.815 543.469 353.662 543.048 353.662 542.589C353.662 542.015 353.815 541.479 354.121 540.905C354.619 539.949 355.422 539.222 356.455 538.686C357.411 538.189 358.483 537.959 359.592 537.959C361.122 537.959 362.423 538.418 363.418 539.375C364.451 540.293 364.948 541.517 364.948 543.048C364.948 544.884 364.183 546.338 362.653 547.41C364.91 548.328 366.019 549.973 366.019 552.384Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M514.498 339.495H416.711C415.028 339.495 413.65 338.118 413.65 336.435V333.986C413.65 332.302 415.028 330.925 416.711 330.925H514.498C516.181 330.925 517.558 332.302 517.558 333.986V336.435C517.558 338.156 516.181 339.495 514.498 339.495Z"
                            fill="#BBD5FC"/>
                        <path
                            d="M539.978 352.696H391.232C389.166 352.696 387.482 351.012 387.482 348.946V347.875C387.482 345.809 389.166 344.125 391.232 344.125H539.978C542.044 344.125 543.727 345.809 543.727 347.875V348.946C543.727 351.012 542.044 352.696 539.978 352.696Z"
                            fill="#BBD5FC"/>
                        <path
                            d="M454.165 443.682C421.914 443.682 391.346 439.626 368.506 433.849C345.475 427.995 333.041 418.468 333.041 409.973C333.041 402.283 343.141 395.243 362.308 389.542C343.983 394.975 334.15 402.972 334.15 409.973C334.15 417.702 346.852 425.393 369.042 431.017C391.767 436.795 421.99 439.971 454.165 439.971C486.34 439.971 516.563 436.795 539.288 431.017C561.439 425.393 574.179 417.741 574.179 409.973C574.179 401.785 560.789 391.57 539.25 383.382C514.765 374.046 484.541 370.602 454.203 370.602C397.237 370.602 373.785 377.26 353.432 385.601C373.862 377.221 397.046 367.732 454.203 367.732C484.656 367.732 514.995 372.898 539.633 382.272C561.631 390.651 575.289 401.288 575.289 410.012C575.289 418.468 562.587 426.35 539.556 432.204C516.716 437.943 486.378 443.682 454.165 443.682Z"
                            fill="#C65447"/>
                        <path
                            d="M649.93 519.25C649.93 519.25 648.859 529.58 647.711 531.532C646.563 533.483 641.59 539.72 639.485 540.944C637.381 542.168 640.786 532.718 640.327 530.881C639.906 529.045 638.605 534.248 637.649 534.363C636.731 534.478 636.96 529.81 636.922 528.126C636.884 526.443 641.437 522.234 643.043 518.599C644.688 514.964 649.93 519.25 649.93 519.25Z"
                            fill="#FFB27D"/>
                        <path
                            d="M523.106 424.055L520.734 419.578C520.734 419.578 521.92 411.619 511.935 406.569C511.935 406.569 508.3 405.077 506.234 408.061C504.321 410.816 509.869 410.319 509.563 414.986C509.257 419.693 511.476 422.448 514.613 423.978L517.176 426.962L523.106 424.055Z"
                            fill="#FFB27D"/>
                        <path
                            d="M617.564 413.341C617.564 413.341 608.994 455.467 561.746 469.241C547.017 473.527 513.503 425.011 513.503 425.011C513.503 425.011 514.689 420.534 522.073 421.567C522.073 421.567 554.668 443.147 558.8 445.519C562.435 447.585 586.384 418.774 597.326 414.106C608.229 409.438 617.564 413.341 617.564 413.341Z"
                            fill="#E58638"/>
                        <path d="M491.904 405.041L490.562 413.777L534.427 420.514L535.768 411.778L491.904 405.041Z"
                              fill="#C65447"/>
                        <path d="M481.022 407.87L490.549 413.801L491.888 405.077L481.022 407.87Z" fill="#FFB27D"/>
                        <path
                            d="M540.398 415.484L539.977 418.239C539.709 419.922 538.141 421.108 536.419 420.84L534.392 420.534L535.731 411.811L537.95 412.155C539.556 412.384 540.666 413.877 540.398 415.484Z"
                            fill="#FFB27D"/>
                        <path
                            d="M673.956 678.151C673.956 678.151 679.809 683.508 678.661 687.296C677.514 691.084 672.234 693.609 671.507 698.277C670.78 702.907 667.949 705.47 665.348 705.47C662.784 705.47 653.067 705.47 653.067 705.47C653.067 705.47 651.192 702.218 655.745 701.223C660.336 700.228 662.058 694.795 661.751 689.706C661.484 684.656 662.172 677.883 662.172 677.883L671.89 675.626L673.956 678.151Z"
                            fill="#233862"/>
                        <path
                            d="M602.07 692.001C602.07 692.001 603.103 704.169 600.539 705.431L567.638 705.355C567.638 705.355 567.638 701.567 572.917 700.725C578.197 699.883 587.493 696.784 589.062 689.897C590.631 682.972 602.07 692.001 602.07 692.001Z"
                            fill="#233862"/>
                        <path
                            d="M574.295 520.091C574.295 520.091 576.017 541.403 578.082 567.077C580.837 601.895 584.204 644.672 584.51 652.783C585.084 666.864 587.379 689.362 587.379 689.362C587.379 689.362 592.812 694.718 603.677 692.461C603.677 692.461 613.356 660.321 616.111 626.306C616.149 625.732 616.187 625.197 616.225 624.623C618.789 589.996 622.806 517.107 622.806 517.107L574.295 520.091Z"
                            fill="#2B478B"/>
                        <path
                            d="M574.295 520.091C574.295 520.091 576.017 541.403 578.082 567.077L616.111 626.344C616.149 625.77 616.187 625.235 616.225 624.661C618.789 590.034 622.806 517.145 622.806 517.145L574.295 520.091Z"
                            fill="#233862"/>
                        <path
                            d="M582.214 522.617C582.214 522.617 613.509 599.867 634.091 636.178C654.674 672.488 661.101 682.628 661.101 682.628C661.101 682.628 672.885 677.998 673.956 674.822C673.956 674.822 666.075 604.726 652.302 587.662C644.497 578.02 635.354 549.055 634.933 538.916C634.512 528.777 632.14 508.728 632.14 508.728L582.214 522.617Z"
                            fill="#2B478B"/>
                        <path
                            d="M623.378 403.584C623.378 403.584 602.222 413.8 590.018 410.012C590.018 410.012 593.04 406.224 598.281 403.393C597.86 400.944 597.516 398.801 597.325 397.003C596.827 392.832 596.827 390.498 596.827 390.498L605.015 384.07L612.743 377.91C612.092 395.205 623.378 403.584 623.378 403.584Z"
                            fill="#FFB27D"/>
                        <path
                            d="M634.55 512.63C634.588 513.931 631.834 515.385 630.61 515.959C621.542 519.938 613.088 522.693 603.523 523.65C592.199 524.798 578.847 522.272 574.218 522.349C573.759 522.349 573.338 522.158 572.955 521.851C572.611 521.622 572.381 508.001 572.611 490.821C572.611 490.132 572.611 489.444 572.649 488.755V488.525C572.726 481.83 572.994 474.789 573.3 467.749C573.95 452.291 575.174 437.408 577.24 429.181C579.727 419.463 585.351 413.035 590.477 408.367L594.686 404.655C598.894 405.497 603.37 405.268 607.846 403.087C612.743 400.714 617.985 398.189 618.826 398.84C625.215 403.508 629.538 413.991 631.834 427.421C633.135 435.15 633.823 443.797 633.747 452.865C633.747 456.194 633.67 459.599 633.479 463.005C632.369 481.294 633.479 497.211 634.129 506.432C634.359 509.072 634.474 511.176 634.55 512.63Z"
                            fill="#EE9849"/>
                        <path
                            d="M634.168 511.789C560.025 511.636 620.51 431.324 620.51 431.324L632.523 427.421C633.48 435.686 633.939 444.907 633.901 454.587C633.901 458.146 633.824 461.781 633.709 465.415C632.868 485.005 633.671 501.994 634.168 511.789Z"
                            fill="#E58638"/>
                        <path
                            d="M612.858 384.529C612.858 384.529 607.502 394.286 597.325 397.003C596.828 392.832 596.828 390.498 596.828 390.498L605.015 384.07L612.858 384.529Z"
                            fill="#ED985F"/>
                        <path
                            d="M593.078 390.192C593.078 390.192 613.278 393.483 615.191 381.086C617.104 368.651 620.968 360.731 608.152 358.091C595.336 355.451 592.084 359.812 590.439 363.83C588.794 367.847 587.875 389.044 593.078 390.192Z"
                            fill="#FFB27D"/>
                        <path
                            d="M622.499 356.598C622.691 356.407 623.111 356.216 624.03 356.178C624.03 356.178 623.265 355.718 622.27 355.68C622.079 354.379 621.39 352.045 619.133 351.51C619.133 351.51 620.357 352.16 620.51 353.537C617.908 350.859 612.323 348.908 596.828 350.132C576.437 351.739 583.17 364.786 585.81 366.355C588.45 367.924 595.107 363.639 600.004 364.021C604.939 364.404 605.857 371.521 607.541 372.515C609.224 373.472 608.535 371.75 610.831 370.755C613.088 369.722 613.317 373.472 612.667 375.921C612.017 378.369 615.116 381.583 615.116 381.583C615.116 381.583 615.116 381.583 618.253 378.216C621.39 374.849 624.336 360.539 622.92 357.478C622.767 357.172 622.652 356.905 622.499 356.598Z"
                            fill="#2C3A64"/>
                        <path
                            d="M618.865 398.763C618.865 398.763 631.643 404.808 637.764 414.106C647.252 428.53 658.27 481.14 658.499 486C658.691 490.859 650.465 525.256 650.465 525.256C650.465 525.256 642.087 524.721 639.945 520.474C639.945 520.474 641.628 485.158 640.557 482.862C639.485 480.605 605.704 435.456 609.491 425.852C613.279 416.248 618.865 398.763 618.865 398.763Z"
                            fill="#EE9849"/>
                        <path
                            d="M520.695 419.348C520.695 419.348 521.384 416.593 519.471 415.369C517.558 414.144 515.798 410.777 514.995 411.237C513.35 412.193 515.913 415.101 515.416 418.774C515.301 420.037 518.285 423.404 520.695 419.348Z"
                            fill="#FFB27D"/>
                        <path
                            d="M543.841 620.642H361.887C360.663 620.642 359.668 619.648 359.668 618.423C359.668 617.199 360.663 616.204 361.887 616.204H543.879C545.103 616.204 546.098 617.199 546.098 618.423C546.059 619.648 545.065 620.642 543.841 620.642Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M543.841 629.52H361.887C360.663 629.52 359.668 628.525 359.668 627.301C359.668 626.076 360.663 625.082 361.887 625.082H543.879C545.103 625.082 546.098 626.076 546.098 627.301C546.059 628.525 545.065 629.52 543.841 629.52Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M543.841 639.353H361.887C360.663 639.353 359.668 638.358 359.668 637.134C359.668 635.909 360.663 634.915 361.887 634.915H543.879C545.103 634.915 546.098 635.909 546.098 637.134C546.059 638.358 545.065 639.353 543.841 639.353Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M519.968 649.148H361.734C360.586 649.148 359.668 648.23 359.668 647.082V646.776C359.668 645.628 360.586 644.71 361.734 644.71H519.968C521.115 644.71 522.034 645.628 522.034 646.776V647.082C522.034 648.23 521.115 649.148 519.968 649.148Z"
                            fill="#D2E4FD"/>
                        <path
                            d="M713.546 705H247.454C247.198 705 247 706 247 707.3V707.7C247 709 247.198 710 247.454 710H713.546C713.802 710 714 709 714 707.7V707.3C714 706.1 713.783 705 713.546 705Z"
                            fill="#E58638"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M826.5 406C832.299 406 837 401.299 837 395.5C837 389.701 832.299 385 826.5 385C820.701 385 816 389.701 816 395.5C816 401.299 820.701 406 826.5 406ZM826.5 414C836.717 414 845 405.717 845 395.5C845 385.283 836.717 377 826.5 377C816.283 377 808 385.283 808 395.5C808 405.717 816.283 414 826.5 414Z"
                              fill="#FBC04F"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M209.022 409.163C210.589 410.72 210.596 413.253 209.039 414.82L186.673 437.326C181.981 442.048 174.342 442.048 169.65 437.326L152.958 420.53C151.394 418.956 148.848 418.956 147.284 420.53L130.593 437.326C125.9 442.048 118.261 442.048 113.569 437.326L96.8777 420.53C95.3136 418.956 92.7672 418.956 91.2031 420.53L68.8373 443.036C67.2801 444.603 64.7474 444.611 63.1805 443.054C61.6135 441.497 61.6055 438.964 63.1627 437.397L85.5286 414.891C90.2209 410.169 97.8599 410.169 102.552 414.891L119.244 431.687C120.808 433.261 123.354 433.261 124.918 431.687L141.609 414.891C146.302 410.169 153.941 410.169 158.633 414.891L175.324 431.687C176.888 433.261 179.435 433.261 180.999 431.687L203.365 409.18C204.922 407.613 207.455 407.606 209.022 409.163Z"
                              fill="#FBC04F"/>
                    </svg>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import ProgressBarSteps from "./ProgressBarStep";

    export default {
        name: "TestingStep",
        components: {ProgressBarSteps},
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                listTypeAnswer: [
                    {
                        value: 0,
                        label: 'Один правильный ответ'
                    },
                    {
                        value: 1,
                        label: 'Несколько правильных ответов'
                    }
                ],
            }
        },
        methods: {
            async onNext() {
                let isValid = await this.$refs.observer.validate();

                function unique(arr) {
                    let result = [];
                    for (let str of arr) {
                        if (!result.includes(str)) {
                            result.push(str);
                        }
                    }
                    return result;
                }

                this.form.data.forEach((form) => {
                    let count_checkbox = 0;
                    let text_repeat = [];
                    let error = false;
                    form.listAnswer.map((answer) => {
                        if (answer.text.trim() === '') {
                            this.$refs.observer.setErrors({
                                [`${answer.uuid}_answer`]: 'Заполните вариант ответа.'
                            });
                            error = true;
                            isValid = false;
                        }
                        if (answer.checkbox) {
                            count_checkbox += 1;
                        }
                        form.listAnswer.map((answer_repeat) => {
                            if (answer.text === answer_repeat.text && answer.uuid !== answer_repeat.uuid) {
                                text_repeat.push(answer.uuid, answer_repeat.uuid)
                            }
                        });
                    });

                    if (form.type_answer === 0 && count_checkbox !== 1 && !error) {
                        form.listAnswer.map((answer) => {
                            this.$refs.observer.setErrors({
                                [`${answer.uuid}_answer`]: 'Выберите правильный ответ.'
                            });
                        });
                        error = true;
                        isValid = false;
                    }

                    if (form.type_answer === 1 && count_checkbox < 2 && !error) {
                        form.listAnswer.map((answer) => {
                            this.$refs.observer.setErrors({
                                [`${answer.uuid}_answer`]: 'Выберите несколько правильных ответов.'
                            });
                        });
                        error = true;
                        isValid = false;
                    }

                    if (text_repeat.length > 0 && !error) {
                        unique(text_repeat).map((uuid) => {
                            this.$refs.observer.setErrors({
                                [`${uuid}_answer`]: 'Введите разные ответы.'
                            });
                        });
                        error = true;
                        isValid = false;
                    }
                });

                if (isValid) {
                    this.form.skip = false;
                    this.$emit('next');
                }
            },
            async onSkip() {
                if (this.$metrika) await this.$metrika.reachGoal('skip-testing-step');
                this.form.skip = true;
                this.$emit('next');
            },
            onPrev() {
                this.$emit('prev');
            },
            addQuestion() {
                this.form.data.push({
                    uuid: this.$uuid.v1(),
                    type_answer: 0,
                    text: '',
                    listAnswer: [
                        {
                            uuid: this.$uuid.v1(),
                            id: 1,
                            text: '',
                            checkbox: false
                        },
                        {
                            uuid: this.$uuid.v1(),
                            id: 2,
                            text: '',
                            checkbox: false
                        }
                    ],
                });
            },
            removeQuestion(indexQuestion) {
                this.form.data.splice(indexQuestion, 1);
            },
            selectAnswer(question, answer) {
                if (question.type_answer === 0) {
                    question.listAnswer.map((el) => el.checkbox = false);
                    answer.checkbox = true;
                } else {
                    answer.checkbox = !answer.checkbox;
                }
            },
            changeTypeAnswer(question) {
                if (question.type_answer === 0) {
                    question.listAnswer.map((el) => el.checkbox = false);
                }
            },
            addAnswer(indexQuestion) {
                this.form.data[indexQuestion].listAnswer.push({
                    uuid: this.$uuid.v1(),
                    id: Object.keys(this.form.data[indexQuestion].listAnswer).length + 1,
                    text: '',
                    checkbox: false
                });
            },
            removeAnswer(indexQuestion, indexAnswer) {
                this.form.data[indexQuestion].listAnswer.splice(indexAnswer, 1);
            },
        },
        async mounted() {
            if (this.$metrika) await this.$metrika.reachGoal('testing-step');
        }
    }
</script>

<style lang="scss" scoped>
    .testing {
        &__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 28px;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            @media (max-width: 1024px) {
                margin-bottom: 20px;
                font-size: 18px;
                line-height: 26px;
            }
            @media (max-width: 767px) {
                justify-content: center;
            }
        }
        &__item {
            margin-bottom: 25px;
            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
        }
        &__answer {
            &-item {
                position: relative;
                & + & {
                    margin-top: 8px;
                    @media (max-width: 767px) {
                        margin-top: 6px;
                    }
                }
            }
            &-input {
                padding-right: 78px;
            }
            &-option-group {
                position: absolute;
                top: 0;
                right: 0;
                height: 56px;
                padding-right: 18px;
                display: flex;
                align-items: center;
                @media (max-width: 1024px) {
                    height: 46px;
                }
            }
            &-option {
                margin: 0;
                padding: 0;
                border: none;
                background: none;
                outline: none;
                cursor: pointer;
                & + & {
                    margin-left: 14px;
                }
                &_selected {
                    svg path {
                        stroke: #38B563;
                    }
                }
            }
            &-add {
                width: 100%;
                height: 56px;
                margin: 8px 0 0;
                padding: 0;
                background: none;
                border: 1px dashed #E0E3F0;
                border-radius: 12px;
                font-size: 18px;
                line-height: 26px;
                cursor: pointer;
                @media (max-width: 1024px) {
                    height: 46px;
                }
            }
        }
        &__add-question {
            position: relative;
            height: 52px;
            margin: 0;
            padding: 0 0 0 70px;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            border: none;
            background: none;
            cursor: pointer;
            @media (max-width: 1024px) {
                font-size: 18px;
                line-height: 26px;
            }
            &:before {
                content: '+';
                position: absolute;
                top: 0;
                left: 0;
                width: 52px;
                height: 52px;
                font-size: 34px;
                line-height: 42px;
                border-radius: 50%;
                background-color: #FBC04F;
                transition: .2s linear all;
            }
            &:hover:before {
                background-color: #FFC956;
            }
        }
        &__remove-question {
            display: flex;
            justify-content: center;
            width: 32px;
            height: 32px;
            padding: 0;
            color: #A4AEBD;
            line-height: 30px;
            border: none;
            border-radius: 50%;
            background: #F2F2F2;
            cursor: pointer;
            transition: .2s linear all;
            @media (max-width: 767px) {
                margin-left: 20px;
            }
            &:hover {
                color: #000000;
            }
        }
    }
</style>
