<template>
    <ValidationObserver tag="form" ref="observer" @submit.prevent="onNext">
        <div class="wizard-starting__page company">
            <div class="wizard-starting__logo">
                <img src="../../../assets/img/wikiworks-logo.png" alt="Wikiworks">
            </div>
            <div class="wizard-starting__row">
                <div class="wizard-starting__col wizard-starting__col_left">
                    <div class="wizard-starting__block">
                        <div>
                            <h3 class="wizard-starting__title">
                                Привет! Для начала нужно подтвердить почту. Письмо с кодом отправлено на {{current_user.email}}
                            </h3>
                            <!--{{form.is_confirm}}-->
                            <ValidationProvider rules="required|max_length:255" vid="confirm" v-slot="provider" slim>
                                <div
                                    class="wizard-starting__form-group"
                                    :class="{'is-invalid': provider.errors.length > 0}"
                                >
                                    <label for="id_confirm" class="wizard-starting__form-group-label">
                                        Код подтверждения
                                    </label>
                                    <input
                                        id="id_confirm"
                                        name="confirm"
                                        type="text"
                                        class="wizard-starting__form-group-input"
                                        :disabled="form.is_confirm"
                                        v-model="form.confirm"
                                        maxlength="255"
                                    >
                                    <div id="company-error"
                                         class="wizard-starting__form-group-error animated fadeInDown"
                                         v-if="provider.errors.length > 0"
                                    >
                                        {{provider.errors[0]}}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <div class="wizard-starting__btn-group">
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_next"
                                    type="button"
                                    @click="onNext"
                                >
                                    Далее
                                </button>
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_code"
                                    type="button"
                                    @click="codeUpdate"
                                >
                                    Повторно отправить код
                                </button>
                            </div>
                        </div>
                        <div>
                            <progress-bar-step :step="1"></progress-bar-step>
                        </div>
                    </div>
                </div>
                <div class="wizard-starting__col wizard-starting__col_right">
                    <svg width="960" height="937" viewBox="0 0 960 937" fill="none" preserveAspectRatio="xMidYMin slice" xmlns="http://www.w3.org/2000/svg">
                        <rect width="960" height="937" fill="#F2F2F2"/>
                        <rect y="540" width="960" height="397" fill="#FBC04F"/>
                        <path d="M712.546 627H246.454C246.198 627 246 628 246 629.3V629.7C246 631 246.198 632 246.454 632H712.546C712.802 632 713 631 713 629.7V629.3C713 628.1 712.783 627 712.546 627Z" fill="#E58638"/>
                        <path d="M650.922 432.082L640.907 559.345H319.093L309.078 432.082L366.05 391.388H593.95L650.922 432.082Z" fill="#FEB920"/>
                        <path d="M650.922 432.082L640.907 559.345H480V391.388H593.95L650.922 432.082Z" fill="#FC9B28"/>
                        <path d="M366.05 310H593.95V566.376H366.05V310Z" fill="white"/>
                        <path d="M480 310H593.95V566.376H480V310Z" fill="white"/>
                        <path d="M406.749 348.819H480V368.848H406.749V348.819Z" fill="#E7E7EA"/>
                        <path d="M406.751 385.651H553.249V405.681H406.751V385.651Z" fill="#E7E7EA"/>
                        <path d="M407 427H553V477H407V427Z" fill="#2B478B"/>
                        <path d="M451.875 452.434L446.523 453.156L450.352 457.277L446.797 459.719L444.121 454.758L441.445 459.719L437.871 457.277L441.719 453.156L436.367 452.434L437.754 448.488L442.676 450.598L441.836 444.992H446.406L445.566 450.598L450.449 448.488L451.875 452.434Z" fill="white"/>
                        <path d="M475.678 452.434L470.327 453.156L474.155 457.277L470.6 459.719L467.924 454.758L465.248 459.719L461.674 457.277L465.522 453.156L460.17 452.434L461.557 448.488L466.479 450.598L465.639 444.992H470.209L469.37 450.598L474.252 448.488L475.678 452.434Z" fill="white"/>
                        <path d="M499.481 452.434L494.13 453.156L497.958 457.277L494.403 459.719L491.727 454.758L489.052 459.719L485.477 457.277L489.325 453.156L483.973 452.434L485.36 448.488L490.282 450.598L489.442 444.992H494.013L493.173 450.598L498.055 448.488L499.481 452.434Z" fill="white"/>
                        <path d="M523.284 452.434L517.933 453.156L521.761 457.277L518.206 459.719L515.53 454.758L512.855 459.719L509.28 457.277L513.128 453.156L507.777 452.434L509.163 448.488L514.085 450.598L513.245 444.992H517.816L516.976 450.598L521.859 448.488L523.284 452.434Z" fill="white"/>
                        <path d="M650.922 432.082V627.42L480 611.543L309.078 627.42V432.082L445.816 529.754H514.184L650.922 432.082Z" fill="#FFCF67"/>
                        <path d="M650.922 432.082V627.42L480 611.543V529.754H514.184L650.922 432.082Z" fill="#FEB920"/>
                        <path d="M650.922 627.42H309.078L480 505.338L650.922 627.42Z" fill="#FAB316"/>
                        <path d="M650.922 627.42H480V505.338L650.922 627.42Z" fill="#FC9B28"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M519 91V141H569L519 91ZM527 110.314V133H549.686L527 110.314Z" fill="#E0E3F0"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M826.5 406C832.299 406 837 401.299 837 395.5C837 389.701 832.299 385 826.5 385C820.701 385 816 389.701 816 395.5C816 401.299 820.701 406 826.5 406ZM826.5 414C836.717 414 845 405.717 845 395.5C845 385.283 836.717 377 826.5 377C816.283 377 808 385.283 808 395.5C808 405.717 816.283 414 826.5 414Z" fill="#FBC04F"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M209.022 409.163C210.589 410.72 210.596 413.253 209.039 414.82L186.673 437.326C181.981 442.048 174.342 442.048 169.65 437.326L152.958 420.53C151.394 418.956 148.848 418.956 147.284 420.53L130.593 437.326C125.9 442.048 118.261 442.048 113.569 437.326L96.8777 420.53C95.3136 418.956 92.7672 418.956 91.2031 420.53L68.8373 443.036C67.2801 444.603 64.7474 444.611 63.1805 443.054C61.6135 441.497 61.6055 438.964 63.1627 437.397L85.5286 414.891C90.2209 410.169 97.8599 410.169 102.552 414.891L119.244 431.687C120.808 433.261 123.354 433.261 124.918 431.687L141.609 414.891C146.302 410.169 153.941 410.169 158.633 414.891L175.324 431.687C176.888 433.261 179.435 433.261 180.999 431.687L203.365 409.18C204.922 407.613 207.455 407.606 209.022 409.163Z" fill="#FBC04F"/>
                    </svg>
                </div>
            </div>
        </div>
        <Keypress key-event="keyup" :key-code="13" @success="onNext"/>
    </ValidationObserver>
</template>

<script>
    import session from '@/api/session';
    import ProgressBarStep from "./ProgressBarStep";
    import {mapState} from 'vuex';

    export default {
        name: "ConfirmEmailStep",
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        components: {
            ProgressBarStep,
            Keypress: () => import('vue-keypress')
        },
        computed: {
            ...mapState('default_data', [
                'current_user'
            ]),
        },
        methods: {
            async codeUpdate() {
                try {
                    await session.post('/api/v1/settings/update_key/');
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        title: 'Новый код подтверждения отправлен на почту',
                        icon: "info",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer);
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                        }
                    });
                } catch (error) {
                    console.error(error);
                }
            },
            async inputPromoHandler() {
                this.form.is_confirm = false;
                try {
                    if (this.form.confirm) {
                        await session.post(`/api/v1/settings/confirm_email/`,
                            {confirm: this.form.confirm});
                        this.form.is_confirm = true;
                        return true;
                    }
                } catch(error) {
                    if (error.response) {
                        this.$refs.observer.setErrors(error.response.data);
                    }
                    return false;
                }
            },
            async onNext() {
                let isValid = await this.$refs.observer.validate();
                let isValidCode = await this.inputPromoHandler();
                console.log('isValid', isValid);
                console.log('isValidCode', isValidCode);

                if (isValid && isValidCode) {
                    console.log('if');
                    if (this.$metrika) await this.$metrika.reachGoal('confirm-email-step');
                    console.log('await');
                    this.$emit('next');
                    console.log('next');
                }
            }
        },
        // async mounted() {
        //     if (this.$metrika) await this.$metrika.reachGoal('confirm-email-step');
        // }
    }
</script>

<style lang="scss" scoped>
    .wizard-starting {

        &__block {
            @media (min-width: 1025px) {
                padding-right: 18%;
            }
        }

        &__title {
            max-width: 650px;
        }

        &__form-group {
            max-width: 300px;
        }

        &__btn-group {
            @media (max-width: 767px) {
                flex-direction: column;
            }
        }
    }
</style>
