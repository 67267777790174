var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor__wrap"},[_c('editor',{attrs:{"api-key":_vm.api,"init":{
                height: _vm.height,
                paste_data_images: true,
                language: 'ru',
                image_advtab: true,
                image_uploadtab: true,
                plugins: _vm.plugins,
                toolbar: _vm.toolbar,
                menubar: _vm.menubar,
                relative_urls : false,
                remove_script_host : false
            }},on:{"input":_vm.updateInput,"onInit":_vm.onEditorLoaded},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoaded),expression:"!isLoaded"}],staticClass:"editor__preloader",style:({minHeight: (_vm.height + "px")})},[_c('loading-spinner')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }