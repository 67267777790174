<template>
    <div class="wizard-starting">
        <transition-group
            name="fade"
            mode="out-in"
            @before-leave="onScrollTop"
        >
            <confirm-email-step
                v-if="step === 1"
                :form="form.step_1"
                @next="onNextStep"
                key="confirm-email-step"
            ></confirm-email-step>
            <company-step
                v-if="step === 2"
                :form="form.step_2"
                @next="onNextStep"
                @prev="onPrevStep"
                key="company-step"
            ></company-step>
            <employees-step
                v-if="step === 3"
                :form="form.step_3"
                @next="onNextStep"
                @prev="onPrevStep"
                key="employees-step"
            ></employees-step>
<!--            <material-step
                v-show="step === 4"
                :form="form.step_4"
                @next="onNextStep"
                @prev="onPrevStep"
                key="material-step"
            ></material-step>-->
<!--            <testing-step
                v-if="step === 5"
                :form="form.step_5"
                @next="onNextStep"
                @prev="onPrevStep"
                key="testing-step"
            ></testing-step>-->
<!--            <learn-step
                v-if="step === 6"
                @next="onNextStep"
                @prev="onPrevStep"
                key="learn-step"
            ></learn-step>-->
            <profile-step
                v-if="step === 4"
                :form="form.step_7"
                @setImageData="image = $event"
                @next="onSubmits"
                @prev="onPrevStep"
                key="profile-step"
            ></profile-step>
        </transition-group>
    </div>
</template>

<script>
    import session from '@/api/session'
    import CompanyStep from './step/CompanyStep'
    import EmployeesStep from './step/EmployeesStep'
    import MaterialStep from './step/MaterialStep'
    import TestingStep from './step/TestingStep'
    import LearnStep from './step/LearnStep'
    import ProfileStep from './step/ProfileStep'
    import ConfirmEmailStep from './step/ConfirmEmailStep'
    import '../../libs/sweetalert2/dist/sweetalert2.min.css'

    export default {
        name: 'WizardStarting',
        components: {
            ConfirmEmailStep,
            ProfileStep,
            LearnStep,
            TestingStep,
            MaterialStep,
            EmployeesStep,
            CompanyStep
        },
        data() {
            return {
                form: {
                    step_1: {
                        is_confirm: false,
                        confirm: ''
                    },
                    step_2: {
                        company: ''
                    },
                    step_3: {
                        number_employees: ''
                    },
                    step_4: {
                        title: '',
                        text: '',
                        skip: false
                    },
                    step_5: {
                        skip: false,
                        data: [
                            {
                                uuid: this.$uuid.v1(),
                                type_answer: 0,
                                text: '',
                                listAnswer: [
                                    {
                                        uuid: this.$uuid.v1(),
                                        id: 1,
                                        text: '',
                                        checkbox: false
                                    },
                                    {
                                        uuid: this.$uuid.v1(),
                                        id: 2,
                                        text: '',
                                        checkbox: false
                                    }
                                ]
                            }
                        ]

                    },
                    step_7: {
                        x: null,
                        y: null,
                        width: null,
                        height: null,
                        last_name: '',
                        first_name: '',
                        phone_number: '',
                        phone_region_code: 1
                    }
                },
                image: null,
                step: null
            }
        },
        async created() {
            try {
                const request = session.get('/api/v1/settings/get_wizard/')
                const response = await request
                if (Object.keys(response.data).length !== 0) {
                    this.form = response.data.draft
                    this.step = response.data.step || 1
                } else {
                    this.step = 1
                    // this.step = 4
                }
            } catch (err) {
                console.error(err)
            }
        },
        methods: {
            onSubmits() {
                const progress_bar = this.$Progress
                this.$Progress.start()
                const url = '/api/v1/settings/wizard_starting/'
                const config = {
                    onUploadProgress(progressEvent) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) /
                            progressEvent.total)
                        progress_bar.set(percentCompleted)
                        return percentCompleted
                    }
                }

                let data = {}
                // data.form.step_7.phone_region_code = this.mobileMask.id
                if (this.image) {
                    config.headers = {
                        'Content-Type': 'multipart/form-data'
                    }
                    const formData = new FormData()
                    formData.append('form', JSON.stringify(this.form))
                    formData.append('image', this.image)
                    data = formData
                } else {
                    data.form = this.form
                    data.image = this.image
                }

                session
                    .post(url, data, config)
                    .then(response => {
                        this.$Progress.finish()
                        this.$cookies.set('wizard_starting', 'true')
                        this.$store.dispatch('default_data/initialize')
                        this.$router.push('/')
                    })
                    .catch((error) => {
                        this.$Progress.finish()
                        if (error.response) {
                            this.$refs.observer.setErrors(error.response.data)
                        }
                    })
            },
            setStep() {
                const data = {
                    step: this.step,
                    draft: this.form
                }
                session.post('/api/v1/settings/set_wizard/', data).then(response => {}).catch((error) => {})
            },
            onNextStep() {
                console.log('this.step', this.step)
                this.step++
                console.log('this.step++', this.step)
                this.setStep()
            },
            onPrevStep() {
                this.step--
            },
            onScrollTop() {
                window.scrollTo(0, 0)
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";
    .wizard-starting {
        position: relative;
        background: #FFFFFF;
        /*overflow-y: scroll;*/
        .fade-enter-active, .fade-leave-active {
            transition: opacity .5s;
        }
        .fade-enter, .fade-enter-to, .fade-leave-to {
            opacity: 0;
        }
        &__row {
            display: flex;
            @media (max-width: 767px) {
                flex-direction: column;
            }
        }
        &__col {
            width: 50%;
            @media (max-width: 767px) {
                width: 100%;
            }
            &_left {
                @media (max-width: 767px) {
                    order: 2;
                }
            }
            &_right {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                @media (max-width: 767px) {
                    position: relative;
                    order: 1;
                    padding-top: 75%;
                }
                svg {
                    width: 100%;
                    height: 100%;
                    @media (max-width: 767px) {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
        &__block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 100vh;
            padding: 220px 22.92% 60px 12.5%;
            @media (max-width: 1024px) {
                padding: 110px 6.5% 25px 6.5%;
            }
            @media (max-width: 767px) {
                min-height: auto;
                padding: 18px 16px 30px;
            }
        }
        &__logo {
            position: absolute;
            top: 72px;
            left: 6.25%;
            width: 210px;
            @media (max-width: 1024px) {
                top: 50px;
                left: 3.25%;
                width: 180px;
            }
            @media (max-width: 767px) {
                position: relative;
                top: auto;
                left: auto;
                width: 100%;
                padding: 20px 0;
                text-align: center;
            }
            img {
                width: 100%;
                @media (max-width: 767px) {
                    width: 160px;
                }
            }
        }
        &__title {
            max-width: 640px;
            font-weight: 600;
            font-size: 34px;
            line-height: 42px;
            color: #222222;
            margin-bottom: 50px;
            @media (max-width: 1024px) {
                margin-bottom: 30px;
                font-size: 20px;
                line-height: 28px;
            }
            @media (max-width: 767px) {
                margin: 0 auto 20px;
                text-align: center;
            }
            &_has-subtitle {
                margin-bottom: 20px;
            }
        }
        &__subtitle {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 28px;
            color: #000000;
            @media (max-width: 1024px) {
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 24px;
            }
            @media (max-width: 767px) {
                text-align: center;
            }
        }
        &__text {
            margin: 24px 0 0 0;
            font-size: 15px;
            line-height: 24px;
            color: #000000;
            @media (max-width: 1024px) {
                margin-top: 20px;
                font-size: 14px;
                line-height: 22px;
            }
            @media (max-width: 767px) {
                text-align: center;
            }
            &_before-subtitle {
                margin: 0 0 26px;
                @media (max-width: 1024px) {
                    margin-bottom: 20px;
                }
            }
        }
        &__form-group {
            max-width: 555px;
            & + & {
                margin-top: 26px;
                @media (max-width: 1024px) {
                    margin-top: 20px;
                }
            }
            @media (max-width: 767px) {
                margin: 0 auto;
                text-align: center;
            }
        }
        &__form-group-label {
            display: block;
            margin-bottom: 15px;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #A4AEBD;
            @media (max-width: 1024px) {
                margin-bottom: 8px;
                font-size: 15px;
                line-height: 24px;
            }
        }
        &__form-group-input {
            width: 100%;
            height: 56px;
            padding: 10px 18px 12px;
            border: 1px solid #E0E3F0;
            border-radius: 12px;
            font-size: 22px;
            line-height: 30px;
            color: #000000;
            transition: .2s linear border-color;
            @media (max-width: 1024px) {
                height: 46px;
                font-size: 16px;
                line-height: 24px;
            }
            &:focus {
                outline: none;
            }
        }
        &__form-group-select {
            .vs__dropdown-toggle {
                height: 56px;
                max-height: none;
                padding: 0;
                border: 1px solid #E0E3F0;
                border-radius: 12px;
                font-size: 22px;
                line-height: 30px;
                color: #000000;
                transition: .2s linear border-color;
                @media (max-width: 1024px) {
                    height: 46px;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .vs__selected-options {
                flex-wrap: nowrap;
                justify-content: flex-start;
                padding: 0 5px 0 18px;
            }
            .vs__search {
                position: absolute;
            }
            .vs__selected {
                position: static !important;
                margin: 0;
                padding: 0;
                justify-content: flex-start;
            }
            .vs__actions {
                padding: 3px 18px 0 5px;
                &:before {
                    content: '';
                    display: inline-block;
                    width: 20px;
                    height: 12px;
                    background: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 1L10 10L1 1' stroke='%23E0E3F0' stroke-width='2'/%3E%3C/svg%3E%0A") no-repeat;
                    background-size: contain;
                    transition: .2s linear all;
                }
            }
            .vs__open-indicator {
                display: none;
            }
            .vs__dropdown-menu {
                border: 1px solid #E0E3F0;
                border-radius: 0 0 12px 12px;
            }
            &.vs--open {
                .vs__dropdown-toggle {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                .vs__actions:before {
                    transform: rotate(180deg);
                }
            }
        }
        &__form-group-area {
            width: 100%;
            padding: 10px 18px 12px;
            border: 1px solid #E0E3F0;
            border-radius: 12px;
            font-size: 22px;
            line-height: 30px;
            color: #000000;
            @media (max-width: 1024px) {
                font-size: 16px;
                line-height: 24px;
            }
            &:focus {
                outline: none;
            }
        }
        &__form-group-error {
            display: none;
            margin-top: 4px;
            font-size: 16px;
            color: #ef5350;
        }
        .is-invalid &__form-group-error {
            display: block;
        }
        .is-invalid &__form-group-input,
        .is-invalid &__form-group-area {
            border-color: #ef5350;
        }
        &__btn-group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 40px;
            margin-bottom: 40px;

            @media (max-width: 1024px) {
                margin-top: 30px;
            }
            @media (max-width: 767px) {
                margin-top: 20px;
                flex-direction: column-reverse;
                justify-content: center;
            }
        }
        &__btn {
            position: relative;
            width: auto;
            min-height: 54px;
            margin-right: 18px;
            margin-bottom: 10px;
            padding-top: 11px;
            padding-bottom: 11px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            border: 2px solid transparent;
            outline: none;
            border-radius: 50px;
            cursor: pointer;
            transition: .2s linear all;
            @media (max-width: 1024px) {
                margin-right: 16px;
            }
            @media (max-width: 767px) {
                min-height: 52px;
                margin-left: 0;
                margin-right: 0;
                padding-top: 12px;
                padding-bottom: 12px;
            }
            &:last-of-type {
                margin-right: 0;
            }
            &_next {
                padding-right: 52px;
                padding-left: 30px;
                background: #FBC04F;
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 30px;
                    display: inline-block;
                    padding: 3px;
                    border: solid #000000;
                    border-width: 0 2px 2px 0;
                    transform: translateY(-50%) rotate(-45deg);
                }
                &:hover {
                    background: #FFC956;
                }
                &:focus {
                    border-color: #D7920D;
                }
            }
            &_prev {
                padding-right: 30px;
                padding-left: 52px;
                background: #F2F2F2;
                color: #A4AEBD;
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 30px;
                    display: inline-block;
                    padding: 3px;
                    border: solid #A4AEBD;
                    border-width: 0 2px 2px 0;
                    transform: translateY(-50%) rotate(135deg);
                    transition: .2s linear all;
                }
                &:hover {
                    color: #000000;
                    &:before {
                        border-color: #000000;
                    }
                }
            }
            &_code {
                padding-right: 30px;
                padding-left: 30px;
                background: #E9E9E9;
                color: #000000;
                &:hover,
                &:focus {
                    background: #DADADA;
                }
            }
        }
        &__progress {
            display: flex;
            margin-top: 70px;
            @media (max-width: 767px) {
                justify-content: center;
                margin: 50px 24px 0;
            }
        }
        &__progress-item {
            width: 44px;
            height: 6px;
            background: #E0E3F0;
            border-radius: 6px;
            margin-right: 12px;
            @media (max-width: 767px) {
                margin-right: 8px;
            }
            &_is-active {
                background: #FBC04F;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
</style>
