<template>
    <wizard-starting-view/>
</template>

<script>
import WizardStartingView from '@/components/wizard-starting/WizardStarting';
export default {
    name: 'WizardStarting',
    components: {WizardStartingView},
    metaInfo() {
        return {
            title: 'Мастер настройки портала'
        }
    }
}
</script>

<style scoped>

</style>
