<template>
    <div class="editor__wrap">
        <editor
            :api-key='api'
            :init="{
                    height,
                    paste_data_images: true,
                    language: 'ru',
                    image_advtab: true,
                    image_uploadtab: true,
                    plugins,
                    toolbar,
                    menubar,
                    relative_urls : false,
                    remove_script_host : false
                }"
            @input="updateInput"
            @onInit="onEditorLoaded"
            v-model="content"
        />
        <div
            v-show="!isLoaded"
            class="editor__preloader"
            :style="{minHeight: `${height}px`}"
        >
            <loading-spinner/>
        </div>
    </div>

</template>

<script>
export default {
    name: "Wysiwyg",
    components: {
        Editor : () => import('@tinymce/tinymce-vue'),
        LoadingSpinner : () => import('./LoadingSpinner')
    },
    props: {
        data: {
            required: true
        },
        height: {
            type: Number,
            required: false,
            default: 400
        }
    },
    data() {
        return {
            plugins: [
                `advlist autolink autosave lists link image charmap print preview anchor`,
                `searchreplace visualblocks fullscreen`,
                `insertdatetime media table paste code wordcount imagetools codesample charmap emoticons fullscreen`,
                `hr tabfocus advimage`
            ],
            toolbar:
                `undo redo restoredraft | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent hr | removeformat | image link | codesample | charmap | emoticons | fullscreen`,
            menubar: `file edit insert view format table tools`,
            api: `6jlw816xuvzicoo06vv8y6ze0g8cwhod53tf3fdgiw5w761h`,
            content: this.data,
            isLoaded: false
        }
    },
    watch: {
        data(newValue, oldValue) {
            this.content = newValue;
        }
    },
    methods: {
        updateInput() {
            this.$emit('input', this.content)
        },
        onEditorLoaded() {
            this.$nextTick(() => {
                this.isLoaded = true
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.editor {
    &__wrap {
        position: relative;
    }
    &__preloader {
        position: absolute;
        z-index: 1000;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ccc;
        background-color: #FFF;
    }
}
</style>
