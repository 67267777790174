<template>
    <div class="wizard-starting__progress">
        <div
            class="wizard-starting__progress-item"
            :class="{ 'wizard-starting__progress-item_is-active': step === index }"
            v-for="index in 4"
            :key="index"
        ></div>
    </div>
</template>

<script>
    export default {
        name: "ProgressBarSteps",
        props: {
            step: {
                type: Number,
                default: 1,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
