<template>
    <ValidationObserver tag="form" ref="observer" @submit.prevent>
        <div class="wizard-starting__page employees" @keypress.enter="onNext">
            <div class="wizard-starting__logo">
                <img src="../../../assets/img/wikiworks-logo.png" alt="Wikiworks">
            </div>
            <div class="wizard-starting__row">
                <div class="wizard-starting__col wizard-starting__col_left">
                    <div class="wizard-starting__block">
                        <div>
                            <h3 class="wizard-starting__title">Сколько сотрудников в вашей компании?</h3>
                            <ValidationProvider rules="required" vid="employees" v-slot="provider">
                                <div
                                    class="wizard-starting__form-group"
                                    :class="{'is-invalid': provider.errors.length > 0}"
                                >
                                    <label class="wizard-starting__form-group-label">В моей компании работает</label>
                                    <div class="employees__wrapper">
                                        <label
                                            class="employees__item"
                                            v-for="(employee, index) in employees"
                                            :key="index"
                                        >
                                            <input
                                                class="employees__control"
                                                type="radio"
                                                ame="employees"
                                                v-model="form.number_employees"
                                                :value="employee"
                                            >
                                            <span class="employees__block">{{ employee }}</span>
                                        </label>
                                    </div>
                                    <div id="employees-error"
                                         class="wizard-starting__form-group-error animated fadeInDown"
                                         v-if="provider.errors.length > 0"
                                    >
                                        {{provider.errors[0]}}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <div class="wizard-starting__btn-group">
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_prev"
                                    @click="onPrev"
                                >
                                    Назад
                                </button>
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_next"
                                    @click="onNext"
                                >
                                    Далее
                                </button>
                            </div>
                        </div>
                        <div>
                            <progress-bar-steps :step="3"></progress-bar-steps>
                        </div>
                    </div>
                </div>
                <div class="wizard-starting__col wizard-starting__col_right">
                    <svg width="960" height="937" viewBox="0 0 960 937" fill="none" xmlns="http://www.w3.org/2000/svg"
                         preserveAspectRatio="xMidYMid slice">
                        <rect width="960" height="937" fill="#F2F2F2"/>
                        <rect y="455" width="960" height="482" fill="#FBC04F"/>
                        <g clip-path="url(#clip0)">
                            <path
                                d="M691.617 518.898H895.308C898.482 518.898 901 521.411 901 524.581C901 527.75 898.482 530.263 895.308 530.263H889.288C884.801 530.263 880.423 532.012 877.249 535.29C874.074 538.46 869.696 540.317 865.209 540.317H842.771C840.144 540.317 837.627 541.301 835.766 543.159C833.905 545.016 831.388 546 828.761 546H751.597C747.547 546 743.607 544.361 740.761 541.519C737.915 538.678 733.975 537.039 729.925 537.039H714.055C710.771 537.039 707.706 535.727 705.408 533.433C703.109 531.138 700.045 529.826 696.761 529.826H691.617C688.552 529.826 686.035 527.313 686.035 524.253C686.035 521.302 688.552 518.898 691.617 518.898Z"
                                fill="#EF9116"/>
                            <path
                                d="M722.264 506.767C703.328 501.522 687.348 486.55 681 467.863C700.045 473.327 714.383 488.626 722.264 506.767Z"
                                fill="#566666"/>
                            <path
                                d="M715.04 509.609C704.532 492.998 702.891 471.032 710.881 453.11C721.279 469.939 721.498 490.922 715.04 509.609Z"
                                fill="#566666"/>
                            <path
                                d="M713.617 509.609C698.403 497.151 689.866 476.824 691.727 457.263C706.941 470.049 713.836 489.829 713.617 509.609Z"
                                fill="#6E7B7C"/>
                            <path
                                d="M708.035 509.609C723.249 497.151 731.786 476.824 729.926 457.263C714.712 470.049 707.926 489.829 708.035 509.609Z"
                                fill="#6E7B7C"/>
                            <path
                                d="M709.02 506.221C727.955 500.757 743.717 485.348 749.736 466.661C730.692 472.343 716.572 487.861 709.02 506.221Z"
                                fill="#566666"/>
                            <path d="M725.219 523.269H706.831L700.373 498.681H731.567L725.219 523.269Z" fill="white"/>
                            <path d="M876.373 519.772H829.199L850.542 511.03H855.03L876.373 519.772Z" fill="#493A60"/>
                            <path d="M855.03 479.447H850.542V486.332H855.03V479.447Z" fill="#471E3B"/>
                            <path d="M855.03 486.441H850.542V511.03H855.03V486.441Z" fill="#493A60"/>
                            <path d="M863.348 452.782H871.995L867.726 472.562H858.97L863.348 452.782Z" fill="#471E3B"/>
                            <path d="M861.159 452.782H869.806L865.537 472.562H856.781L861.159 452.782Z" fill="#592E4F"/>
                            <path
                                d="M872.105 479.447H843.319C841.567 479.447 840.145 478.027 840.145 476.278V475.732C840.145 473.983 841.567 472.562 843.319 472.562H872.105C873.856 472.562 875.279 473.983 875.279 475.732V476.278C875.279 478.027 873.856 479.447 872.105 479.447Z"
                                fill="#471E3B"/>
                            <path
                                d="M857.876 479.447H829.09C827.338 479.447 825.916 478.027 825.916 476.278V475.732C825.916 473.983 827.338 472.562 829.09 472.562H857.876C859.627 472.562 861.05 473.983 861.05 475.732V476.278C861.05 478.027 859.627 479.447 857.876 479.447Z"
                                fill="#865978"/>
                            <path
                                d="M884.692 419.342H865.1C863.677 419.342 862.363 420.325 862.035 421.855L856.343 449.176C855.905 451.143 857.438 452.891 859.408 452.891H879C880.423 452.891 881.736 451.908 882.065 450.378L887.756 423.057C888.085 421.09 886.662 419.342 884.692 419.342Z"
                                fill="#471E3B"/>
                            <path
                                d="M881.189 419.342H861.597C860.174 419.342 858.861 420.325 858.532 421.855L852.841 449.176C852.403 451.143 853.935 452.891 855.906 452.891H875.498C876.92 452.891 878.234 451.908 878.562 450.378L884.254 423.057C884.692 421.09 883.159 419.342 881.189 419.342Z"
                                fill="#865978"/>
                            <path d="M728.503 412.457H720.075V444.258H728.503V412.457Z" fill="#675186"/>
                            <path d="M743.06 412.457H728.393V444.258H743.06V412.457Z" fill="#471E3B"/>
                            <path d="M726.204 435.843H722.264V441.963H726.204V435.843Z" fill="white"/>
                            <path d="M726.204 414.643H722.264V432.893H726.204V414.643Z" fill="white"/>
                            <path d="M737.806 412.457H729.378V444.258H737.806V412.457Z" fill="#F7507B"/>
                            <path d="M752.363 412.457H737.697V444.258H752.363V412.457Z" fill="#471E3B"/>
                            <path d="M735.508 435.843H731.567V441.963H735.508V435.843Z" fill="white"/>
                            <path d="M735.508 414.643H731.567V432.893H735.508V414.643Z" fill="white"/>
                            <path
                                d="M833.686 417.047C828.433 413.113 824.711 407.211 823.179 400.873C822.413 397.813 822.851 395.3 822.851 392.349C822.851 389.071 823.617 385.792 825.806 383.279C827.338 381.53 829.637 380.328 832.045 380C834.453 379.672 836.423 380.765 838.721 381.093C843.756 381.749 847.477 384.481 849.776 388.961C852.184 393.66 852.841 398.906 854.264 403.933C855.686 408.96 858.204 414.096 862.691 416.61C853.935 419.997 841.239 422.839 833.686 417.047Z"
                                fill="#471E3B"/>
                            <path
                                d="M860.831 456.826L859.737 452.782H835.219L811.249 467.863L827.339 468.519L853.498 466.88C858.533 466.552 862.035 461.743 860.831 456.826Z"
                                fill="#41495C"/>
                            <path
                                d="M865.975 459.886L864.881 452.782H859.846L860.94 456.826C862.254 461.743 858.642 466.552 853.607 466.88L827.338 468.519L831.826 475.185L855.358 473.327C862.144 472.781 866.96 466.552 865.975 459.886Z"
                                fill="#3B3B4E"/>
                            <path
                                d="M818.254 519.773C817.707 519.663 817.269 519.554 816.721 519.335C816.174 519.117 815.736 518.68 815.299 518.243C814.642 518.68 813.985 519.117 813.219 519.445L810.92 520.428C809.607 520.975 808.622 521.958 808.075 523.27H815.189C815.955 521.849 817.05 520.647 818.254 519.773Z"
                                fill="#798098"/>
                            <path
                                d="M825.806 519.226C823.946 519.772 821.975 519.991 820.005 519.991C819.458 519.991 818.801 519.882 818.254 519.772C817.05 520.647 815.956 521.849 815.299 523.27H826.354L825.806 519.226Z"
                                fill="#5E647A"/>
                            <path
                                d="M819.567 518.789C819.129 519.117 818.692 519.445 818.363 519.663C818.911 519.773 819.458 519.773 820.115 519.882C822.085 519.991 824.055 519.663 825.916 519.117L825.259 514.308L821.1 475.95H821.647C821.209 475.185 820.771 474.42 820.224 473.765C819.896 473.327 819.567 473 819.239 472.562L823.179 510.593C823.508 513.762 822.194 516.931 819.567 518.789Z"
                                fill="#F7507B"/>
                            <path
                                d="M818.253 519.772C818.691 519.445 819.129 519.117 819.566 518.789C822.193 516.931 823.616 513.762 823.288 510.593L819.347 472.562C817.925 471.142 816.173 470.049 814.203 469.503C812.561 469.065 810.92 469.175 809.278 469.83C808.293 471.36 807.964 473.218 808.512 475.076L819.457 512.341C819.895 513.762 819.347 515.401 818.144 516.275L816.173 517.696C815.954 517.915 815.626 518.024 815.407 518.242C815.845 518.68 816.283 519.007 816.83 519.335C817.268 519.554 817.706 519.663 818.253 519.772Z"
                                fill="#FF9493"/>
                            <path
                                d="M811.249 467.863C810.373 468.41 809.717 469.065 809.279 469.83C810.811 469.284 812.562 469.175 814.204 469.503C816.722 470.049 818.801 471.688 820.443 473.655C820.99 474.311 821.428 475.076 821.866 475.841L831.936 475.076L827.448 468.41L811.249 467.863Z"
                                fill="#F7507B"/>
                            <path
                                d="M826.572 520.756C825.368 520.974 824.164 520.974 823.07 520.537C822.413 520.209 821.975 519.663 821.756 519.117C820.99 519.772 820.005 520.319 819.02 520.756C817.706 521.302 816.612 522.395 816.174 523.706L815.846 524.69H834.124L833.03 518.898C830.95 519.772 828.761 520.428 826.572 520.756Z"
                                fill="#798098"/>
                            <path
                                d="M823.07 520.537C824.164 521.084 825.368 520.975 826.572 520.756C828.761 520.428 830.95 519.772 833.03 518.789L832.045 513.871L833.796 475.185C834.015 471.579 831.06 468.41 827.448 468.41C823.617 468.41 820.552 471.797 821.1 475.622L825.368 510.811C825.587 512.56 825.259 514.418 824.383 515.948C823.726 517.04 822.851 518.133 821.866 518.898C821.975 519.663 822.413 520.21 823.07 520.537Z"
                                fill="#FF9493"/>
                            <path
                                d="M827.229 427.647C827.557 424.15 828.761 420.762 830.622 417.921L829.637 412.457C827.776 413.003 826.244 414.424 825.696 416.282L816.612 444.258H824.93L828.104 436.608C827.229 433.658 826.9 430.598 827.229 427.647Z"
                                fill="#798098"/>
                            <path
                                d="M830.622 417.812C828.761 420.763 827.557 424.15 827.229 427.538C826.901 430.598 827.229 433.658 828.105 436.499L832.264 426.555L830.622 417.812Z"
                                fill="#5E647A"/>
                            <path
                                d="M827.119 440.761L824.711 437.483C824.055 436.608 823.07 436.281 822.084 436.499L816.174 437.811C815.408 437.92 814.751 438.466 814.423 439.122L813.547 440.761C812.672 442.291 813.876 444.258 815.627 444.258H821.537V440.761H827.119Z"
                                fill="#FF9493"/>
                            <path
                                d="M864.771 452.782C860.065 440.652 857.985 424.806 859.846 411.91L843.318 408.741L829.637 412.348C828.98 413.659 829.199 415.626 829.637 417.047C829.965 418.467 830.512 419.888 830.512 421.418C830.512 424.369 828.542 427.101 828.98 430.051C829.199 431.691 830.293 433.22 831.826 434.095C833.796 434.641 835.985 434.423 837.736 433.33C838.721 432.674 839.816 431.691 841.02 431.909C842.224 432.128 842.88 433.33 843.866 433.876C845.179 434.641 846.93 434.313 848.353 433.767C849.776 433.22 851.199 432.455 852.841 432.455C854.373 432.455 856.124 433.439 856.234 434.969C856.124 434.969 856.015 434.969 855.905 434.969H833.468C834.343 435.625 834.89 436.499 835.109 437.592C836.094 442.509 835.985 447.755 835.219 452.673H864.771V452.782Z"
                                fill="#939BB4"/>
                            <path
                                d="M856.124 435.078C856.124 433.548 854.373 432.456 852.731 432.565C851.199 432.565 849.776 433.33 848.244 433.876C846.821 434.423 845.07 434.75 843.756 433.985C842.771 433.439 842.005 432.237 840.91 432.018C839.706 431.8 838.612 432.783 837.627 433.439C835.876 434.532 833.686 434.75 831.716 434.204C831.826 434.313 832.045 434.423 832.154 434.423C832.592 434.641 833.03 434.86 833.358 435.188H855.796C855.905 435.188 856.015 435.078 856.124 435.078Z"
                                fill="#798098"/>
                            <path
                                d="M843.318 408.086C843.647 407.539 843.975 406.883 844.194 406.228C842.662 404.042 841.129 402.403 841.129 402.403L835.219 406.993C836.642 407.976 837.846 409.397 838.612 410.927C839.269 410.927 839.925 410.818 840.582 410.599C841.786 410.162 842.662 409.178 843.318 408.086Z"
                                fill="#F7507B"/>
                            <path
                                d="M844.303 406.228C844.084 406.883 843.756 407.43 843.428 408.085C842.771 409.178 841.786 410.162 840.691 410.599C840.035 410.817 839.378 410.927 838.721 410.927C838.94 411.473 839.159 412.02 839.268 412.566C840.472 413.659 842.005 414.424 843.647 414.205C845.179 413.877 846.602 412.894 847.368 411.364C846.492 409.615 845.398 407.867 844.303 406.228Z"
                                fill="#FF9493"/>
                            <path
                                d="M840.691 395.737C840.144 393.77 838.393 392.131 836.423 391.693C836.423 390.273 835.109 388.961 833.577 388.961C832.154 388.852 830.731 389.836 829.856 391.038C828.98 392.24 828.652 393.661 828.433 395.191C828.214 396.72 828.104 398.141 827.667 399.562C827.448 400.218 827.229 400.764 826.9 401.31C827.01 401.529 827.01 401.747 827.119 401.966C828.104 404.37 829.637 406.665 831.935 407.867C834.672 409.288 838.174 408.851 840.691 406.993C841.786 406.228 842.552 405.245 843.318 404.152C842.771 403.387 842.333 402.622 842.005 401.747C841.348 399.89 841.348 397.813 840.691 395.737Z"
                                fill="#FF9493"/>
                            <path
                                d="M859.518 432.784L860.722 436.062L865.428 435.079C863.786 433.877 861.707 433.002 859.518 432.784Z"
                                fill="#5E647A"/>
                            <path
                                d="M868.602 434.314L864.881 418.686C864.224 415.845 862.363 413.441 859.846 411.911L858.204 414.424C856.015 417.812 855.468 422.074 856.891 425.899L859.518 432.893C861.597 433.111 863.677 433.986 865.428 435.188L860.722 436.171L840.911 440.433V444.258H859.408L862.254 443.821C866.741 442.947 869.587 438.685 868.602 434.314Z"
                                fill="#798098"/>
                            <path
                                d="M830.075 440.761L832.483 437.483C833.139 436.608 834.124 436.281 835.11 436.499L841.02 437.811C841.786 437.92 842.443 438.466 842.771 439.122L843.647 440.761C844.522 442.291 843.318 444.258 841.567 444.258H835.657V440.761H830.075Z"
                                fill="#FF9493"/>
                            <path d="M752.363 460.214H722.702V526.33H752.801V460.651L752.363 460.214Z" fill="#D09DA0"/>
                            <path d="M752.801 460.651V450.706H722.702V460.214H752.363L752.801 460.651Z" fill="#D09DA0"/>
                            <path d="M787.498 495.184V450.706H752.801V460.651L787.498 495.184Z" fill="#8E6168"/>
                            <path d="M752.801 460.651V526.33H787.498V495.184L752.801 460.651Z" fill="#B27D82"/>
                            <path d="M853.607 444.149H717.557V450.597H853.607V444.149Z" fill="#D09DA0"/>
                            <path d="M893.886 444.149H853.607V450.597H893.886V444.149Z" fill="#B27D82"/>
                            <path d="M849.557 450.706H722.702V460.323H849.557V450.706Z" fill="#B27D82"/>
                            <path d="M886.333 450.706H849.557V460.323H886.333V450.706Z" fill="#8E6168"/>
                            <path d="M849.557 460.213H845.398V526.329H849.557V460.213Z" fill="#D09DA0"/>
                            <path d="M853.716 460.213H849.557V526.329H853.716V460.213Z" fill="#B27D82"/>
                            <path d="M882.284 460.213H878.125V526.329H882.284V460.213Z" fill="#D09DA0"/>
                            <path d="M886.334 460.213H882.174V526.329H886.334V460.213Z" fill="#B27D82"/>
                            <path d="M834.343 440.761H801.836V444.258H834.343V440.761Z" fill="#A9BEFF"/>
                            <path d="M845.726 440.761H834.343V444.258H845.726V440.761Z" fill="#95A8F7"/>
                            <path
                                d="M793.189 430.816H748.532C746.671 430.816 745.249 429.286 745.249 427.538V392.568C745.249 390.71 746.781 389.289 748.532 389.289H793.189C795.05 389.289 796.472 390.819 796.472 392.568V427.538C796.472 429.396 795.05 430.816 793.189 430.816Z"
                                fill="#7984C9"/>
                            <path
                                d="M792.97 392.677C792.97 390.819 791.438 389.289 789.577 389.289H745.139C743.278 389.289 741.746 390.819 741.746 392.677V427.429C741.746 429.286 743.278 430.816 745.139 430.816H747.438L792.97 395.409V392.677Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M747.438 430.816H789.577C791.437 430.816 792.97 429.286 792.97 427.428V395.409L747.438 430.816Z"
                                fill="#95A8F7"/>
                            <path
                                d="M768.562 442.837L766.482 441.526V437.482L771.298 413.331H762.98H761.667L756.194 438.575C755.537 441.526 757.836 444.258 760.791 444.258H761.667H768.562H772.283V442.837H768.562Z"
                                fill="white"/>
                            <path
                                d="M770.642 442.837C768.562 442.837 766.92 440.87 767.358 438.794L771.408 419.451V413.222L765.935 438.466C765.278 441.417 767.577 444.149 770.532 444.149H782.134V442.728H770.642V442.837Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M801.179 442.182H799.209V441.089H801.179C801.726 441.089 802.164 440.652 802.164 440.105V435.734C802.164 435.188 801.726 434.751 801.179 434.751H799.209V433.658H801.179C802.383 433.658 803.368 434.641 803.368 435.843V440.215C803.368 441.307 802.383 442.182 801.179 442.182Z"
                                fill="white"/>
                            <path d="M799.318 432.456H789.905V443.821H793.189L799.318 433.33V432.456Z" fill="white"/>
                            <path d="M799.318 433.33L793.189 443.821H799.318V433.33Z" fill="#FBE1FF"/>
                        </g>
                        <g clip-path="url(#clip1)">
                            <path
                                d="M132.56 486.271H322.804C326.267 486.271 329 489.099 329 492.476C329 495.943 326.175 498.681 322.804 498.681H313.693C311.051 498.681 308.5 499.685 306.677 501.601C304.855 503.426 302.304 504.521 299.662 504.521H264.856C261.941 504.521 259.116 505.707 257.112 507.715C255.016 509.814 252.283 510.909 249.367 510.909H219.846C216.749 510.909 213.742 509.631 211.555 507.441C209.368 505.251 206.362 503.973 203.264 503.973H156.25C154.154 503.973 152.058 503.152 150.601 501.601C149.143 500.141 147.047 499.228 144.952 499.228H132.56C128.916 499.228 126 496.308 126 492.658C126 489.282 128.916 486.271 132.56 486.271Z"
                                fill="#EF9116"/>
                            <path
                                d="M230.78 425.04H189.232L178.846 395.566C178.39 394.38 179.301 393.102 180.577 393.102H218.115C218.935 393.102 219.664 393.65 219.846 394.38L230.78 425.04Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M232.42 425.04H190.873L180.486 395.566C180.03 394.38 180.941 393.102 182.217 393.102H219.847C220.667 393.102 221.396 393.65 221.578 394.38L232.42 425.04Z"
                                fill="#41495C"/>
                            <path d="M192.148 423.124L182.217 394.927H219.847L229.778 423.124H192.148Z" fill="#939BB4"/>
                            <path d="M201.806 400.95H188.23L191.602 410.44H205.177L201.806 400.95Z" fill="#798098"/>
                            <path d="M209.095 410.44H221.851L218.48 400.95H205.724L209.095 410.44Z" fill="#FBE1FF"/>
                            <path d="M194.244 417.74L196.157 423.124H209.642L207.728 417.74H194.244Z" fill="#B7BECE"/>
                            <path d="M211.646 417.74L213.56 423.124H226.316L224.402 417.74H211.646Z" fill="#B7BECE"/>
                            <path
                                d="M219.847 394.927H215.018H202.262H198.435H184.859H182.217L183.219 397.847H185.861H199.437H203.355H216.02H220.849L219.847 394.927Z"
                                fill="#B7BECE"/>
                            <path d="M192.421 412.539L193.697 416.372H207.273L205.906 412.539H192.421Z" fill="#FBE1FF"/>
                            <path d="M222.58 412.539H209.824L211.191 416.372H223.947L222.58 412.539Z" fill="#798098"/>
                            <path d="M242.169 422.394H213.742V425.04H242.169V422.394Z" fill="#7984C9"/>
                            <path d="M134.292 494.666H137.298L160.623 439.367H153.607L134.292 494.666Z" fill="#B27D82"/>
                            <path d="M156.796 430.151L153.607 439.367H160.623L164.45 430.151H156.796Z" fill="#8E6168"/>
                            <path d="M176.386 430.151H168.732L172.559 439.367H179.575L176.386 430.151Z" fill="#8E6168"/>
                            <path d="M198.8 494.666L179.575 439.367H172.559L195.793 494.666H198.8Z" fill="#B27D82"/>
                            <path d="M247.272 494.666H250.279L273.513 439.367H266.497L247.272 494.666Z" fill="#B27D82"/>
                            <path d="M269.686 430.151L266.497 439.367H273.513L277.34 430.151H269.686Z" fill="#8E6168"/>
                            <path d="M289.275 430.151H281.622L285.448 439.367H292.464L289.275 430.151Z" fill="#8E6168"/>
                            <path d="M311.78 494.666L292.464 439.367H285.448L308.773 494.666H311.78Z" fill="#B27D82"/>
                            <path d="M187.593 425.041H140.943V430.242H187.593V425.041Z" fill="#D09DA0"/>
                            <path d="M304.582 425.041H187.593V430.242H304.582V425.041Z" fill="#B27D82"/>
                            <path
                                d="M251.099 485.814H242.899L240.165 489.373C239.71 489.921 239.163 490.377 238.525 490.56C237.523 490.925 236.703 491.746 236.338 492.75H252.557L251.099 485.814Z"
                                fill="#471E3B"/>
                            <path
                                d="M236.338 492.75C236.338 492.841 236.247 492.932 236.247 493.023L235.792 494.666H252.921L252.556 492.75H236.338Z"
                                fill="white"/>
                            <path d="M250.005 459.99H239.072L239.892 466.743H250.461L250.005 459.99Z" fill="#471E3B"/>
                            <path d="M239.892 466.743L242.352 485.815H251.736L250.461 466.743H239.892Z" fill="#6E4261"/>
                            <path
                                d="M216.476 456.979C217.296 457.526 218.207 457.891 218.844 458.713C219.482 459.534 219.664 460.538 220.029 461.45C220.667 463.002 221.942 464.279 223.491 465.009L222.671 454.515H215.018C214.744 455.428 215.747 456.431 216.476 456.979Z"
                                fill="#471E3B"/>
                            <path
                                d="M239.527 436.812L213.924 441.01C209.551 441.74 206.544 445.937 207.455 450.317L214.926 487.366H225.404L223.582 465.009C222.033 464.279 220.758 463.001 220.12 461.45C219.755 460.538 219.573 459.443 218.935 458.713C218.298 457.983 217.386 457.526 216.566 456.979C215.746 456.431 214.835 455.427 215.109 454.515H222.762L229.596 454.241H257.658V436.903H239.527V436.812Z"
                                fill="#6E4261"/>
                            <path
                                d="M223.583 487.366H214.927L212.011 491.107C211.556 491.655 210.918 492.111 210.28 492.385C209.187 492.75 208.367 493.571 207.911 494.666H225.04L223.583 487.366Z"
                                fill="#471E3B"/>
                            <path
                                d="M207.911 494.666C207.911 494.757 207.82 494.849 207.82 494.94L207.364 496.674H225.496L225.131 494.666H207.911Z"
                                fill="white"/>
                            <path
                                d="M272.692 436.812L271.69 427.413C271.052 421.208 270.141 415.002 268.956 408.889L267.498 401.223C266.952 398.212 264.309 395.931 261.212 395.931H238.707L237.431 397.756C239.071 399.216 240.893 401.132 242.533 402.318C242.989 403.87 242.442 405.512 241.896 406.972C241.258 408.432 240.438 409.892 240.256 411.444C239.982 413.086 240.347 414.82 240.073 416.554C239.982 417.557 239.527 418.561 238.889 419.2C239.345 425.04 239.527 430.789 239.527 436.629H272.692V436.812Z"
                                fill="white"/>
                            <path
                                d="M240.256 411.535C240.529 409.984 241.349 408.524 241.896 407.064C242.533 405.604 243.08 403.961 242.533 402.41C240.893 401.132 239.162 399.216 237.431 397.847L236.52 399.216L237.067 402.957C237.796 408.432 238.433 413.816 238.798 419.291C239.436 418.561 239.891 417.558 239.982 416.645C240.347 414.911 239.982 413.178 240.256 411.535Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M205.815 420.934L198.708 419.839C197.979 419.748 197.25 419.93 196.612 420.295L193.697 422.303C193.241 422.577 192.968 423.033 192.695 423.489L192.148 424.949H194.244L195.337 423.672L196.886 424.949H205.542L205.815 420.934Z"
                                fill="#FF9493"/>
                            <path
                                d="M202.353 425.04C202.353 422.577 204.266 420.569 206.635 420.387L222.215 419.2L235.518 397.938C236.702 396.022 239.162 395.292 241.167 396.478C243.262 397.665 243.9 400.402 242.625 402.41L229.504 421.664C228.138 423.763 225.86 425.04 223.309 425.04H202.353Z"
                                fill="white"/>
                            <path
                                d="M244.811 379.14C242.442 377.498 241.258 374.395 241.896 371.566C242.533 368.738 244.993 366.456 247.818 366C250.642 365.544 253.649 367.004 255.107 369.468C257.203 368.646 259.572 369.194 261.485 370.471C263.398 371.658 264.765 373.665 265.403 375.855C266.041 378.045 266.132 380.327 265.767 382.608C265.403 384.707 264.765 386.714 263.581 388.539C262.396 390.273 260.756 391.733 258.752 392.555C258.478 393.193 257.84 393.65 257.294 394.015C256.747 394.288 256.018 394.471 255.38 394.471C254.014 394.653 252.556 394.562 251.189 394.288C250.096 390.821 248.82 387.171 247.727 383.703C247.18 381.969 246.269 380.144 244.811 379.14Z"
                                fill="#471E3B"/>
                            <path
                                d="M255.198 391.825C254.196 392.463 253.012 392.828 251.827 392.646C252.465 391.277 252.556 389.635 252.009 388.175C253.012 388.54 254.196 388.266 255.016 387.627C255.836 386.988 256.292 385.893 256.292 384.798C256.292 384.251 256.201 383.703 255.927 383.247C255.289 382.243 253.832 381.969 252.738 382.608C251.736 383.156 251.189 384.433 251.189 385.619C250.734 385.346 250.46 384.889 250.369 384.342C250.278 383.794 250.278 383.338 250.369 382.791C250.46 381.513 250.643 380.418 250.734 379.141C248.638 378.502 246.998 376.403 246.725 374.213C245.723 374.669 244.811 375.217 243.991 375.947C243.9 376.312 243.809 376.677 243.809 377.042C243.9 378.411 243.991 379.688 243.627 381.057C243.354 382.334 242.534 383.612 241.349 384.251C241.987 384.798 242.716 385.437 243.354 385.984C243.445 387.262 243.536 388.631 243.627 390C243.718 390.73 243.718 391.46 244.083 392.098C244.538 392.92 245.54 393.376 246.451 393.467C247.363 393.558 248.365 393.376 249.276 393.193V396.113H257.476L257.203 392.463C257.203 392.463 256.383 393.193 254.652 393.193C254.834 392.737 255.016 392.281 255.198 391.825Z"
                                fill="#FF9493"/>
                            <path
                                d="M230.325 455.245L226.225 435.808C225.951 434.713 226.225 433.618 226.953 432.706C227.682 431.793 232.238 431.337 233.331 431.337H256.383V433.253H233.331C232.785 433.253 232.238 433.527 231.874 433.892C231.509 434.348 231.418 434.896 231.509 435.443L235.609 454.88L230.325 455.245Z"
                                fill="#471E3B"/>
                            <path
                                d="M229.596 455.245L225.496 435.808C225.222 434.713 225.496 433.618 226.224 432.706C226.953 431.793 227.956 431.337 229.14 431.337H252.192V433.253H229.14C228.593 433.253 228.047 433.527 227.682 433.892C227.318 434.348 227.227 434.896 227.318 435.443L231.418 454.88L229.596 455.245Z"
                                fill="#675186"/>
                            <path
                                d="M274.97 459.99H242.169L262.852 405.33H288.546C290.095 405.33 291.188 406.699 290.915 408.25L281.804 454.424C281.166 457.618 278.341 459.99 274.97 459.99Z"
                                fill="#675186"/>
                            <path
                                d="M243.809 454.15H229.504C227.864 454.15 226.589 455.428 226.589 457.07C226.589 458.713 227.864 459.99 229.504 459.99H243.08C246.452 459.99 249.367 457.709 250.187 454.424L262.852 405.33C259.116 405.33 255.745 407.885 254.834 411.535L243.809 454.15Z"
                                fill="#7984C9"/>
                            <path d="M274.514 496.582H235.245L253.012 489.282H256.747L274.514 496.582Z" fill="#493A60"/>
                            <path d="M256.747 459.99H253.012V465.739H256.747V459.99Z" fill="#332A44"/>
                            <path d="M256.747 465.739H253.012V489.374H256.747V465.739Z" fill="#493A60"/>
                            <path
                                d="M153.243 403.596C154.154 403.961 155.247 403.779 155.976 403.049C156.432 402.592 156.705 401.954 157.252 401.68C157.981 401.315 158.983 401.771 159.712 401.315C160.532 400.95 160.623 399.855 160.532 398.942C160.441 398.03 160.259 397.117 160.805 396.387C161.352 395.657 162.354 395.566 163.174 395.11C164.268 394.562 164.997 393.285 164.905 392.007C164.905 391.368 164.632 390.821 164.268 390.273C163.265 390.182 162.263 390.182 161.625 389.452C161.079 388.904 160.988 387.992 161.079 387.262C161.17 386.441 161.534 385.711 161.808 384.981C162.263 383.429 162.354 381.695 161.625 380.327C160.988 378.867 159.53 377.772 157.89 377.68C156.25 377.589 154.701 378.867 154.519 380.418C154.336 381.513 154.883 382.608 155.156 383.703C155.43 384.798 155.521 386.076 154.61 386.806C153.607 387.718 151.967 387.262 150.874 387.992C149.781 388.813 149.872 390.638 150.601 391.824C151.33 393.011 152.514 393.832 153.425 394.927C154.336 396.022 154.883 397.573 154.154 398.76C153.516 399.855 151.967 400.311 151.603 401.497C151.512 401.771 151.512 401.954 151.603 402.227C152.058 402.866 152.605 403.322 153.243 403.596Z"
                                fill="#6E7B7C"/>
                            <path
                                d="M165.817 394.836C166.728 393.741 167.092 392.007 166.09 391.003C165.634 390.547 164.906 390.456 164.268 390.364C164.632 390.821 164.814 391.459 164.906 392.098C164.997 393.376 164.268 394.562 163.174 395.201C162.354 395.657 161.352 395.748 160.805 396.478C160.259 397.208 160.441 398.212 160.532 399.033C160.623 399.946 160.532 400.95 159.712 401.406C158.983 401.771 157.981 401.406 157.252 401.771C156.705 402.045 156.432 402.683 155.976 403.14C155.248 403.87 154.154 403.961 153.243 403.687C152.605 403.413 151.967 402.957 151.603 402.41C151.694 403.14 152.241 403.778 152.787 404.235C153.608 404.873 154.519 405.238 155.248 405.877C156.979 407.337 157.343 410.166 156.159 411.991C157.708 413.177 160.714 412.539 162.081 411.079C161.17 410.805 160.805 409.619 161.17 408.797C161.534 407.885 162.263 407.337 163.083 406.79C163.903 406.242 164.723 405.786 165.179 404.965C165.634 404.143 165.726 402.957 164.997 402.41C164.45 401.953 163.721 402.045 163.174 401.68C161.99 400.95 162.081 399.124 162.901 397.938C163.721 396.661 164.997 395.931 165.817 394.836Z"
                                fill="#566666"/>
                            <path
                                d="M151.056 409.071L152.878 420.752C155.065 420.843 157.343 420.66 159.439 419.93C161.17 419.383 162.81 418.562 164.268 417.467L165.543 409.071H151.056Z"
                                fill="white"/>
                            <path
                                d="M152.878 420.751L153.516 425.04H163.083L164.268 417.466C162.81 418.561 161.17 419.383 159.439 419.93C157.343 420.66 155.156 420.934 152.878 420.751Z"
                                fill="#FBE1FF"/>
                            <path d="M172.103 477.967H154.245V494.666H172.103V477.967Z" fill="#939BB4"/>
                            <path d="M172.103 473.77H154.245V477.967H172.103V473.77Z" fill="#798098"/>
                            <path d="M172.103 468.75H154.245V473.769H172.103V468.75Z" fill="#939BB4"/>
                            <path d="M182.946 477.967H172.194V494.666H182.946V477.967Z" fill="#798098"/>
                            <path d="M182.946 473.77H172.194V477.967H182.946V473.77Z" fill="#5E647A"/>
                            <path d="M182.946 468.75H172.194V473.769H182.946V468.75Z" fill="#798098"/>
                            <path
                                d="M174.655 498.317C175.201 498.134 175.748 497.952 176.203 497.769C176.75 497.587 177.388 497.313 177.57 496.765C177.752 496.126 177.297 495.488 176.841 495.031C176.386 494.484 175.839 493.936 175.383 493.389C175.11 493.024 174.837 492.75 174.381 492.568C174.017 492.385 173.47 492.385 173.197 492.75C172.832 493.206 173.015 493.936 172.65 494.393C172.468 494.666 172.012 494.849 171.739 495.031C171.101 495.396 170.737 496.218 170.828 496.948C170.919 497.678 171.466 498.317 172.195 498.59C173.015 498.864 173.835 498.59 174.655 498.317Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M190.873 492.111C190.417 491.563 189.688 491.29 188.959 491.016C188.048 490.742 187.137 490.377 186.135 490.103C185.679 489.921 185.042 489.83 184.586 490.012C184.039 490.286 183.857 491.016 183.675 491.655C183.584 492.293 183.493 492.932 183.31 493.48C183.219 494.027 183.128 494.575 183.401 495.122C183.584 495.487 184.039 495.761 184.495 495.852C184.95 495.943 185.406 495.943 185.862 495.852C186.499 495.852 187.046 495.761 187.684 495.761C188.413 495.67 189.233 495.67 189.87 495.305C190.417 495.031 190.873 494.483 191.055 493.845C191.419 493.388 191.237 492.658 190.873 492.111Z"
                                fill="#FBE1FF"/>
                        </g>
                        <g clip-path="url(#clip2)">
                            <path
                                d="M251.227 707.138H667.386C672.727 707.138 677 711.396 677 716.718C677 722.041 672.727 726.298 667.386 726.298H660.122C651.791 726.298 643.886 729.492 638.118 735.453C632.35 741.201 624.446 744.607 616.114 744.607H582.573C575.096 744.607 567.832 747.588 562.492 752.91C557.151 758.232 550.101 761.213 542.41 761.213H391.157C381.971 761.213 372.998 757.594 366.589 750.994C359.966 744.394 351.207 740.775 342.021 740.775H289.253C283.272 740.775 277.504 738.433 273.445 734.175C269.172 729.918 263.617 727.576 257.636 727.576H251.227C245.458 727.576 240.972 722.892 240.972 717.357C240.972 711.609 245.458 707.138 251.227 707.138Z"
                                fill="#EF9116"/>
                            <path d="M591.119 724.809H498.829L540.488 707.564H549.46L591.119 724.809Z" fill="#493A60"/>
                            <path d="M549.247 629.646H540.488V643.058H549.247V629.646Z" fill="#471E3B"/>
                            <path d="M549.247 643.058H540.488V707.564H549.247V643.058Z" fill="#493A60"/>
                            <path
                                d="M558.433 472.745H624.019C629.359 472.745 633.418 477.854 632.137 483.176L601.373 602.822C599.451 609.847 593.042 614.956 585.778 614.956H494.129L523.397 597.925L558.433 472.745Z"
                                fill="#675186"/>
                            <path
                                d="M548.392 472.745H613.978C619.319 472.745 623.378 477.854 622.096 483.176L591.333 602.822C589.41 609.847 583.001 614.956 575.737 614.956H484.088L513.356 597.925L548.392 472.745Z"
                                fill="#7984C9"/>
                            <path d="M575.524 614.956H483.875V629.858H575.524V614.956Z" fill="#493A60"/>
                            <path
                                d="M480.883 617.085C483.447 616.234 486.224 616.021 488.788 615.382C491.351 614.743 494.128 613.466 495.41 611.124C497.333 607.931 496.692 603.034 500.11 601.331C502.246 600.267 505.024 601.331 507.16 600.267C510.365 598.989 511.005 594.944 512.928 592.177C515.492 588.558 521.046 587.493 524.892 589.622C528.524 591.751 532.796 593.241 537.069 594.732L540.701 570.888H505.878L437.515 612.402C429.611 617.298 425.765 626.665 428.329 635.607L449.479 714.164H476.183L458.238 633.478L470.201 629.22C470.415 627.73 470.842 626.24 471.483 624.962C473.406 621.556 476.824 618.363 480.883 617.085Z"
                                fill="#6E4261"/>
                            <path
                                d="M537.068 594.37C533.009 592.88 528.523 591.39 524.891 589.261C521.046 586.919 515.705 588.197 512.928 591.816C511.005 594.583 510.151 598.628 507.16 599.906C504.81 600.757 502.246 599.693 500.11 600.97C496.905 602.673 497.332 607.57 495.41 610.763C494.128 613.105 491.351 614.382 488.787 615.021C486.223 615.66 483.446 615.873 480.883 616.724C476.824 618.001 473.192 621.195 471.269 625.027C470.628 626.304 470.201 627.794 469.987 629.285L535.359 605.867L537.068 594.37Z"
                                fill="#471E3B"/>
                            <path
                                d="M533.437 571.526L492.847 624.324C486.437 632.839 484.942 643.91 489.001 653.703L514.851 714.59H540.487L516.56 641.142L553.946 618.575C565.696 611.55 572.959 599.202 573.814 585.577L574.455 571.526H533.437Z"
                                fill="#6E4261"/>
                            <path
                                d="M471.483 714.803H451.401L449.478 718.848C447.769 722.467 444.778 725.022 441.147 726.512C439.01 727.363 437.087 728.641 435.378 730.131H475.114L471.483 714.803Z"
                                fill="#471E3B"/>
                            <path
                                d="M435.379 730.131C434.311 731.195 433.242 732.686 432.602 733.963H475.969L475.115 730.131H435.379Z"
                                fill="white"/>
                            <path
                                d="M537.069 714.803H516.987L515.064 718.848C513.355 722.467 510.364 725.022 506.732 726.512C504.596 727.363 502.673 728.641 500.964 730.131H540.7L537.069 714.803Z"
                                fill="#471E3B"/>
                            <path
                                d="M500.965 730.131C499.897 731.195 498.828 732.686 498.188 733.963H541.555L540.701 730.131H500.965Z"
                                fill="white"/>
                            <path
                                d="M521.474 457.629L524.251 450.178C525.319 447.198 527.455 444.43 530.233 442.514L536.214 438.469L546.042 455.075L541.342 459.546C539.205 461.674 536.214 462.952 533.224 462.952L528.096 463.165L521.474 457.629Z"
                                fill="#FF9493"/>
                            <path
                                d="M544.76 455.714L546.042 455.075L553.092 428.463L540.274 424.844L538.992 425.483C538.778 425.696 538.564 425.909 538.564 426.122L531.087 450.817C530.874 451.456 531.301 452.094 531.942 452.307L543.692 455.714C544.119 455.926 544.333 455.926 544.76 455.714Z"
                                fill="#7984C9"/>
                            <path
                                d="M545.187 455.075L533.224 451.668C532.583 451.455 532.155 450.817 532.369 450.178L539.633 425.27C539.846 424.631 540.487 424.205 541.128 424.418L553.092 427.824C553.732 428.037 554.16 428.676 553.946 429.315L546.469 454.223C546.469 454.862 545.828 455.287 545.187 455.075Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M512.928 502.975C513.783 500.846 513.997 498.717 514.638 496.588C515.919 493.395 518.697 490.84 521.047 488.073C522.756 485.944 524.251 483.176 524.251 480.409L515.492 489.989L508.229 506.807C510.578 507.233 512.074 505.104 512.928 502.975Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M515.492 509.149L523.61 503.614L525.533 497.866C521.688 500.846 518.056 504.678 515.492 509.149Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M524.678 480.409L524.465 480.622C524.465 483.389 522.969 486.157 521.26 488.286C518.91 491.053 516.133 493.395 514.851 496.801C513.996 498.93 513.783 501.059 513.142 503.188C512.287 505.317 510.792 507.446 508.656 507.446L515.919 490.628L529.592 463.59C527.883 459.971 524.037 457.842 520.192 457.842H518.91L489.001 504.678C487.933 506.382 487.506 508.298 487.506 510.214V511.491C487.933 517.026 493.06 520.858 498.401 519.794C499.683 519.581 500.965 519.155 501.819 518.304L515.492 509.149C518.269 504.891 521.687 501.059 525.746 497.866L533.437 475.512C530.019 476.151 526.815 477.854 524.678 480.409Z"
                                fill="white"/>
                            <path
                                d="M590.264 504.04L583.641 507.233C581.932 508.936 582.145 511.491 582.786 513.833C583.427 515.961 584.709 518.09 585.564 520.219C586.418 522.348 586.845 524.903 585.564 526.819C583.855 529.587 580.223 529.799 577.018 530.225C573.386 530.651 569.755 531.29 566.55 532.78C563.346 534.27 560.355 536.825 559.073 540.231C557.364 544.276 558.005 549.173 560.355 553.005C562.705 556.837 566.337 559.604 570.396 561.52C573.386 563.011 576.591 563.862 580.009 564.501L592.614 532.141L590.264 504.04Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M560.355 552.792C558.005 548.96 557.364 544.063 559.073 540.018C560.569 536.612 563.346 534.27 566.55 532.567C569.755 531.077 573.387 530.438 577.019 530.013C580.223 529.8 583.855 529.374 585.564 526.606C586.846 524.69 586.418 522.136 585.564 520.007C584.709 517.878 583.428 515.962 582.787 513.62C582.146 511.491 581.932 508.723 583.641 507.02L590.264 503.827L588.127 475.725L570.182 472.958L555.655 471.254L533.437 475.512L502.033 566.417C501.178 568.972 503.101 571.739 505.878 571.739H577.446L580.223 564.501C576.805 564.075 573.6 563.011 570.609 561.521C566.337 559.392 562.705 556.624 560.355 552.792ZM525.533 517.878H568.473C566.123 519.581 563.132 521.284 560.141 522.348H571.037C566.337 525.755 559.287 528.097 553.305 528.097C536.001 528.097 529.378 521.497 525.533 517.878Z"
                                fill="white"/>
                            <path
                                d="M560.141 522.348C563.132 521.071 566.123 519.581 568.473 517.877H525.533C529.378 521.497 536.001 528.096 553.305 528.096C559.073 528.096 566.337 525.754 571.037 522.348H560.141Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M604.364 536.186C604.15 535.76 604.364 534.27 604.15 533.844C603.936 533.206 603.723 532.354 603.509 531.928C602.868 530.438 601.586 529.374 600.091 528.735C598.382 527.883 596.246 527.458 594.964 525.754C593.896 524.477 593.682 522.774 593.041 521.284C591.973 519.155 589.623 517.665 587.273 517.239L591.546 529.586C596.459 529.586 601.8 531.928 604.364 536.186Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M585.137 496.588L583.641 507.02L585.35 511.704C587.273 507.02 587.059 501.485 585.137 496.588Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M611.414 534.696L596.673 484.241C595.391 480.196 592.186 477.002 588.127 475.725L585.137 496.588C587.059 501.485 587.059 506.807 585.35 511.704L587.273 517.239C589.623 517.665 591.973 518.942 593.041 521.284C593.682 522.774 594.109 524.477 594.964 525.755C596.246 527.245 598.168 527.884 600.091 528.735C601.586 529.374 602.868 530.438 603.509 531.929C603.723 532.567 603.936 533.206 604.15 533.845C604.364 534.483 604.15 535.761 604.364 536.186C601.8 532.141 596.673 529.8 591.759 529.8L559.073 537.251V545.128H603.723C606.286 545.128 608.636 544.063 610.132 541.934C611.627 539.806 612.268 537.251 611.414 534.696Z"
                                fill="white"/>
                            <path
                                d="M538.351 545.128C542.196 536.186 551.382 533.419 556.723 535.122C560.141 536.186 563.346 538.102 564.841 540.657C566.123 542.573 564.414 545.128 562.278 545.128H538.351Z"
                                fill="#FF9493"/>
                            <path d="M555.655 471.467L551.81 483.815L561.85 477.641L559.714 473.596L555.655 471.467Z"
                                  fill="#FBE1FF"/>
                            <path d="M570.182 473.17L565.482 473.596L561.851 477.641L568.473 487.221L570.182 473.17Z"
                                  fill="#FBE1FF"/>
                            <path
                                d="M563.346 466.997C566.551 465.719 569.328 463.377 571.891 461.036C572.319 458.907 572.746 456.778 573.173 454.649C568.046 456.565 561.851 457.842 556.296 457.842C556.083 461.249 555.869 464.442 555.655 467.848C558.433 468.274 560.996 467.848 563.346 466.997Z"
                                fill="#F7507B"/>
                            <path
                                d="M563.346 466.997C560.996 468.061 558.219 468.274 555.869 467.848C555.869 469.126 555.655 470.19 555.655 471.467C557.792 473.383 559.714 475.512 561.851 477.428C564.414 475.725 567.191 474.022 570.182 472.958C570.61 468.913 571.251 464.868 572.105 460.823C569.328 463.377 566.764 465.719 563.346 466.997Z"
                                fill="#FF9493"/>
                            <path
                                d="M546.255 426.121C542.623 424.631 539.419 422.076 537.496 418.67C535.787 415.264 535.573 410.793 537.283 407.387C538.992 403.768 542.623 401.213 546.682 401.213C550.528 401 554.587 403.555 555.869 407.387C560.782 406.748 566.123 406.11 570.823 406.961C575.737 408.026 580.223 410.58 582.573 415.051C584.923 413.987 587.487 415.69 588.982 417.819C590.264 419.948 590.477 422.715 590.264 425.27C590.05 430.166 589.196 435.276 587.7 439.959C586.632 443.579 584.923 447.411 581.932 449.965C577.873 453.584 571.464 454.223 566.337 452.52C560.996 450.817 556.723 446.772 553.519 442.301C550.314 437.83 548.392 431.231 546.255 426.121Z"
                                fill="#471E3B"/>
                            <path
                                d="M570.609 426.547C569.968 428.463 569.327 430.592 569.114 432.508C568.9 434.637 569.327 436.766 570.823 438.469C571.891 439.747 573.6 440.598 573.814 442.301C574.027 443.366 573.814 444.43 573.387 445.495C572.959 446.559 572.532 447.411 572.532 448.475C572.532 449.54 573.173 451.03 574.241 451.03C574.668 449.327 575.309 447.624 576.591 446.346C577.873 445.069 579.582 444.217 581.505 444.43C583.214 444.643 584.923 445.708 585.564 447.411C586.418 449.54 585.35 451.882 583.855 453.372C581.291 455.926 577.446 456.778 574.241 455.501C570.396 459.12 565.482 461.675 560.141 462.313C554.8 462.952 549.246 461.462 545.4 457.842C540.487 453.372 538.564 446.346 538.991 439.747C539.419 433.147 541.769 426.76 543.905 420.586C551.809 424.631 561.637 427.186 570.609 426.547Z"
                                fill="#FF9493"/>
                            <path
                                d="M273.658 604.737C269.385 593.667 259.772 584.513 258.704 572.591C258.063 565.991 261.054 558.54 267.249 556.411C273.658 554.282 280.494 558.54 285.194 563.436C297.585 575.997 304.635 593.028 307.412 610.486C310.19 627.943 309.121 645.613 306.771 663.07C306.344 666.263 305.917 669.669 304.421 672.65C302.926 675.63 300.149 678.185 296.944 678.611C293.953 679.037 291.176 677.759 288.826 676.056C281.99 671.16 278.358 662.857 277.076 654.554C275.795 646.251 276.435 637.949 276.649 629.433C276.649 621.13 276.435 612.402 273.658 604.737Z"
                                fill="#566666"/>
                            <path
                                d="M256.781 595.583C277.931 612.828 289.467 640.929 286.69 667.966C286.476 670.521 286.049 672.863 284.767 675.205C283.485 677.334 281.349 679.25 278.785 679.25C277.076 679.25 275.581 678.611 274.086 677.759C265.967 673.927 257.636 669.244 253.79 661.367C248.236 650.084 253.363 636.033 247.595 624.749C244.818 619.214 239.477 615.169 235.845 610.06C232.213 604.95 230.291 597.286 234.35 592.603C237.127 589.409 241.827 588.558 245.886 589.622C249.945 590.474 253.577 592.816 256.781 595.583Z"
                                fill="#6E7B7C"/>
                            <path
                                d="M323.435 591.538C326.64 588.345 330.058 585.151 334.544 583.874C338.817 582.597 344.158 583.448 346.935 587.067C349.285 590.261 349.071 594.944 347.576 598.564C346.081 602.183 343.517 605.589 341.381 608.995C336.681 617.085 334.972 626.452 333.476 635.607C331.981 644.761 330.913 654.128 327.494 662.857C324.076 671.585 317.667 679.462 308.695 682.656C303.567 684.359 296.945 683.933 294.167 679.462C292.886 677.546 292.672 674.992 292.672 672.65C291.177 642.845 302.499 612.827 323.435 591.538Z"
                                fill="#6E7B7C"/>
                            <path d="M273.872 689.255L275.153 694.365H324.93L326.426 689.255H273.872Z" fill="#FBE1FF"/>
                            <path d="M283.485 724.808H316.812L324.93 694.365H275.153L283.485 724.808Z" fill="white"/>
                            <path d="M330.058 675.417H270.026L273.872 689.255H326.426L330.058 675.417Z" fill="white"/>
                            <path d="M581.718 545.128H303.78V558.327H581.718V545.128Z" fill="#D09DA0"/>
                            <path d="M581.59 558.388H676.871V545.189H581.59V558.388Z" fill="#B27D82"/>
                            <path d="M312.539 558.328H303.78V583.023H312.539V558.328Z" fill="#B27D82"/>
                            <path d="M312.539 583.236H303.78V732.473H312.539V583.236Z" fill="#D09DA0"/>
                            <path d="M581.505 583.236H572.746V732.473H581.505V583.236Z" fill="#D09DA0"/>
                            <path d="M581.505 558.328H572.746V583.023H581.505V558.328Z" fill="#B27D82"/>
                            <path d="M364.666 558.328H312.54V583.023H364.666V558.328Z" fill="#8E6168"/>
                            <path d="M364.666 583.236H312.54V732.473H364.666V583.236Z" fill="#B27D82"/>
                            <path d="M633.845 583.236H581.718V732.473H633.845V583.236Z" fill="#B27D82"/>
                            <path d="M633.845 558.328H581.718V583.023H633.845V558.328Z" fill="#8E6168"/>
                            <path d="M380.475 536.612H350.994V545.128H380.475V536.612Z" fill="#FFEAD4"/>
                            <path d="M409.957 536.612H380.475V545.128H409.957V536.612Z" fill="#F7507B"/>
                            <path d="M376.844 525.329H347.362V531.929H376.844V525.329Z" fill="#FFEAD4"/>
                            <path d="M406.325 525.329H376.844V531.929H406.325V525.329Z" fill="#6E4261"/>
                            <path d="M373.212 531.929H343.73V536.612H373.212V531.929Z" fill="white"/>
                            <path d="M402.693 531.929H373.212V536.612H402.693V531.929Z" fill="#7984C9"/>
                            <path d="M375.134 520.646H345.653V525.329H375.134V520.646Z" fill="white"/>
                            <path d="M404.616 520.646H375.134V525.329H404.616V520.646Z" fill="#7984C9"/>
                            <path d="M378.766 515.961H349.285V520.645H378.766V515.961Z" fill="#FFEAD4"/>
                            <path d="M408.248 515.961H378.766V520.645H408.248V515.961Z" fill="#F7507B"/>
                            <path
                                d="M336.253 494.034C342.235 491.053 346.294 484.88 347.576 478.493C348.217 474.874 348.003 471.254 347.362 467.635C345.226 464.016 342.021 461.036 338.39 459.12C333.903 456.778 328.562 455.713 323.649 456.778C318.735 457.842 314.035 461.036 311.685 465.506C309.335 469.977 309.122 475.725 311.685 479.983C313.181 482.751 315.744 485.092 316.385 488.073C316.599 488.925 316.599 489.989 316.599 491.053C317.026 491.479 317.453 491.905 317.881 492.118C322.581 496.589 330.271 497.014 336.253 494.034Z"
                                fill="#6E7B7C"/>
                            <path
                                d="M347.362 467.636C348.217 471.255 348.217 474.874 347.576 478.493C346.294 485.093 342.235 491.054 336.253 494.034C330.271 497.015 322.581 496.589 317.453 492.118C317.026 491.693 316.599 491.267 316.172 491.054C316.172 492.118 315.958 493.396 316.172 494.46C316.599 498.505 319.803 502.124 323.862 503.402C327.708 504.679 332.194 504.253 336.04 502.55C344.371 499.144 350.567 490.628 350.994 481.474C351.208 476.79 349.926 471.894 347.362 467.636Z"
                                fill="#566666"/>
                            <path d="M328.562 480.196L326.426 510.214H328.562H330.485L328.562 480.196Z" fill="#B27D82"/>
                            <path
                                d="M334.544 527.245C338.176 522.136 340.526 516.388 342.021 510.214H310.831L318.094 538.103C324.503 536.825 330.485 532.568 334.544 527.245Z"
                                fill="white"/>
                            <path
                                d="M342.021 510.214C340.526 516.388 338.389 522.136 334.544 527.245C330.485 532.78 324.503 536.825 317.88 538.103L319.589 545.128H337.107L346.08 510.214H342.021Z"
                                fill="#FBE1FF"/>
                            <path
                                d="M490.497 540.444L476.611 494.885C476.183 493.395 474.688 492.331 473.192 492.331H398.634C397.139 492.331 396.071 493.821 396.498 495.098L411.879 545.128H489.642H491.992H513.783C515.706 545.128 517.201 543.637 517.201 541.721V540.444H490.497Z"
                                fill="#95A8F7"/>
                            <path
                                d="M469.988 492.331H395.643C394.148 492.331 393.08 493.821 393.507 495.098L408.889 545.128H488.788L473.406 494.885C472.979 493.395 471.484 492.331 469.988 492.331Z"
                                fill="#A9BEFF"/>
                            <path
                                d="M447.77 519.368C449.052 524.051 446.274 527.67 441.574 527.67C436.874 527.67 432.174 523.838 430.893 519.368C429.611 514.684 432.388 511.065 437.088 511.065C441.788 511.065 446.488 514.684 447.77 519.368Z"
                                fill="white"/>
                        </g>
                        <g clip-path="url(#clip3)">
                            <path d="M114.831 345.642H59.0845V351.83H114.831V345.642Z" fill="#E8EAF0"/>
                            <path
                                d="M86.9576 351.915C78.0044 351.915 69.0512 351.915 60.0979 351.915C59.6756 351.915 59.0843 352.17 58.9999 351.576C58.9154 350.898 59.5911 350.898 60.0979 350.813C60.9426 350.729 61.8717 350.559 62.8008 350.559C79.7782 350.559 96.84 350.559 113.817 350.559C114.409 350.559 115.084 350.474 115 351.322C115 351.661 114.831 351.831 114.409 351.831C114.155 351.831 113.902 351.831 113.648 351.831C104.78 351.915 95.9109 351.915 86.9576 351.915Z"
                                fill="#DBDDE4"/>
                            <path d="M111.452 298H96.0796V345.642H111.452V298Z" fill="#E8EAF0"/>
                            <path
                                d="M108.073 312.411C108.073 315.124 108.073 317.922 108.073 320.634C108.073 321.228 107.989 321.397 107.313 321.397C104.948 321.397 102.668 321.397 100.303 321.397C99.5425 321.397 99.458 320.973 99.458 320.38C99.458 316.481 99.458 312.666 99.458 308.766C99.458 307.24 99.458 305.63 99.458 304.104C99.458 303.426 99.7114 303.341 100.303 303.341C102.668 303.341 105.033 303.341 107.398 303.341C107.989 303.341 108.073 303.51 108.073 304.104C107.989 306.901 108.073 309.614 108.073 312.411Z"
                                fill="#FDFDFD"/>
                            <path
                                d="M106.384 336.995C106.384 338.521 105.286 339.623 103.766 339.708C102.245 339.708 101.063 338.606 101.063 337.08C101.063 335.469 102.161 334.367 103.766 334.367C105.286 334.283 106.384 335.385 106.384 336.995Z"
                                fill="#DBDDE4"/>
                            <path
                                d="M103.85 338.945C102.583 338.945 101.739 338.097 101.739 336.911C101.739 335.808 102.668 334.876 103.766 334.876C104.864 334.876 105.709 335.724 105.709 336.911C105.709 338.097 104.864 338.945 103.85 338.945Z"
                                fill="#FFE592"/>
                            <path d="M78.1733 298H62.8008V345.642H78.1733V298Z" fill="#E8EAF0"/>
                            <path
                                d="M70.4869 334.791C72.0073 334.791 73.1053 335.978 73.1053 337.504C73.1053 339.03 71.9228 340.132 70.4024 340.132C68.8821 340.132 67.6996 338.945 67.784 337.419C67.784 335.893 68.8821 334.791 70.4869 334.791Z"
                                fill="#DBDDE4"/>
                            <path
                                d="M66.1792 304.019C68.8821 304.019 71.5005 304.019 74.2033 303.934C74.879 303.934 74.7946 304.443 74.7946 304.782C74.7946 309.275 74.7946 313.683 74.7946 318.176C74.7946 319.193 74.7946 320.21 74.7946 321.312C74.7946 321.652 74.7101 321.991 74.2878 321.991C71.5849 321.991 68.9665 321.991 66.2637 321.906C66.1792 315.887 66.1792 309.953 66.1792 304.019Z"
                                fill="#FDFDFD"/>
                            <path
                                d="M72.4298 337.504C72.4298 338.606 71.5007 339.539 70.4026 339.454C69.3046 339.454 68.46 338.521 68.46 337.419C68.46 336.317 69.3891 335.385 70.4871 335.385C71.6696 335.554 72.5143 336.402 72.4298 337.504Z"
                                fill="#FFE592"/>
                            <path d="M94.813 298H79.4404V345.642H94.813V298Z" fill="#DBDDE4"/>
                            <path
                                d="M91.3498 312.835C91.3498 315.548 91.3498 318.261 91.3498 320.973C91.3498 321.736 91.1808 321.991 90.3362 321.991C88.0556 321.906 85.8596 321.991 83.579 321.991C82.9878 321.991 82.7344 321.821 82.7344 321.228C82.7344 315.717 82.7344 310.122 82.7344 304.612C82.8188 304.104 82.9878 303.934 83.4946 303.934C85.8596 303.934 88.309 303.934 90.674 303.934C91.2653 303.934 91.3498 304.104 91.3498 304.612C91.3498 307.41 91.3498 310.122 91.3498 312.835Z"
                                fill="#FDFDFD"/>
                            <path
                                d="M87.1267 334.791C88.647 334.791 89.7451 335.893 89.7451 337.419C89.7451 339.03 88.647 340.216 87.1267 340.216C85.6063 340.216 84.3394 339.114 84.3394 337.588C84.4238 335.893 85.4374 334.791 87.1267 334.791Z"
                                fill="#E8EAF0"/>
                            <path
                                d="M87.1268 339.539C86.0287 339.539 85.0996 338.691 85.0996 337.504C85.0996 336.402 85.9443 335.47 87.1268 335.47C88.2248 335.47 89.1539 336.317 89.1539 337.419C89.1539 338.522 88.2248 339.454 87.1268 339.539Z"
                                fill="#FFE592"/>
                        </g>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M436.5 368C446.165 368 454 360.165 454 350.5C454 340.835 446.165 333 436.5 333C426.835 333 419 340.835 419 350.5C419 360.165 426.835 368 436.5 368ZM436.5 376C450.583 376 462 364.583 462 350.5C462 336.417 450.583 325 436.5 325C422.417 325 411 336.417 411 350.5C411 364.583 422.417 376 436.5 376Z"
                              fill="#FBC04F"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M841.821 202.164C843.387 203.722 843.394 206.255 841.836 207.821L808.707 241.121C804.016 245.836 796.384 245.836 791.693 241.121L764.236 213.522C762.672 211.95 760.128 211.95 758.564 213.522L731.107 241.121C726.416 245.836 718.784 245.836 714.093 241.121L686.636 213.522C685.072 211.95 682.528 211.95 680.964 213.522L647.836 246.821C646.278 248.387 643.745 248.394 642.179 246.836C640.613 245.278 640.606 242.745 642.164 241.179L675.293 207.879C679.984 203.164 687.616 203.164 692.307 207.879L719.764 235.478C721.328 237.05 723.872 237.05 725.436 235.478L752.893 207.879C757.584 203.164 765.216 203.164 769.907 207.879L797.364 235.478C798.928 237.05 801.472 237.05 803.036 235.478L836.164 202.179C837.722 200.613 840.255 200.606 841.821 202.164Z"
                              fill="#E0E3F0"/>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="220" height="166" fill="white" transform="translate(681 380)"/>
                            </clipPath>
                            <clipPath id="clip1">
                                <rect width="203" height="145" fill="white" transform="translate(126 366)"/>
                            </clipPath>
                            <clipPath id="clip2">
                                <rect width="445" height="360" fill="white" transform="translate(232 401)"/>
                            </clipPath>
                            <clipPath id="clip3">
                                <rect width="56" height="54" fill="white" transform="translate(59 298)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
        <Keypress key-event="keyup" :key-code="13" @success="onNext"/>
    </ValidationObserver>
</template>

<script>
    import ProgressBarSteps from "./ProgressBarStep";

    export default {
        name: "EmployeesStep",
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                employees: [
                    'До 10 сотрудников',
                    'От 10 до 30 сотрудников',
                    'От 30 до 100 сотрудников',
                    'От 100 до 500 сотрудников',
                    'Больше 500 сотрудников'
                ]
            }
        },
        components: {
            ProgressBarSteps,
            Keypress: () => import('vue-keypress')
        },
        methods: {
            async onNext() {
                let isValid = await this.$refs.observer.validate();
                if (isValid) {
                    this.$emit('next');
                }
            },
            onPrev() {
                this.$emit('prev');
            }
        },
        async mounted() {
            if (this.$metrika) await this.$metrika.reachGoal('employees-step');
        }
    }
</script>

<style lang="scss" scoped>
    .employees {
        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: -16px;
            @media (max-width: 1024px) {
                margin-bottom: -9px;
            }
            @media (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
            }
        }
        &__item {
            position: relative;
            display: block;
            margin-right: 14px;
            margin-bottom: 16px;
            @media (max-width: 1024px) {
                margin-bottom: 9px;
            }
            @media (max-width: 767px) {
                margin-right: 0;
            }
        }
        &__control {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            opacity: 0;
            visibility: hidden;
        }
        &__control:checked ~ &__block:before {
            border-color: #77BDFD;
        }
        &__control:checked ~ &__block:after {
            opacity: 1;
            visibility: visible;
        }
        &__block {
            display: block;
            padding: 10px 10px 10px 50px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #000000;
            border: 1px solid #E0E3F0;
            border-radius: 30px;
            cursor: pointer;
            transition: .2s linear all;
            @media (max-width: 1024px) {
                font-size: 14px;
                padding-left: 44px;
            }
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 10px;
                width: 26px;
                height: 26px;
                border: 1px solid #585858;
                border-radius: 50%;
                transform: translateY(-50%);
                transition: .2s linear all;
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 15px;
                width: 16px;
                height: 16px;
                background: #77BDFD;
                border-radius: 50%;
                transform: translateY(-50%);
                transition: .2s linear all;
                opacity: 0;
                visibility: hidden;
            }
            &:hover:before {
                border-color: #77BDFD;
            }
        }
        .wizard-starting__block {
            @media (min-width: 1025px) {
                padding-right: 8.33%;
            }
        }
    }
</style>
